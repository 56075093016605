import useSWR from "swr";
import { api } from "../services";

const BASE_URL = "/system-admin/employee-shifts";

export const useEmployeeShift = ( query = {} ) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);

  const create = async (data) => {
    const employeeShift = await api.post(BASE_URL, data);
    return employeeShift;
  };

  return {
    isLoading,
    data: data,
    count: data?.length,
    mutate,
    create
  };
};