import React from "react";
import { Row, Col, Layout } from "antd";
import UserManagementBody from "../../components/user-management/UserManagementBody";

const UserManagement = () => {
  return (
    <>
      <Layout style={{ minHeight: "80vh", padding: "20px" }}>
        <Row justify={"center"}>
          <Col>
            <UserManagementBody/>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default UserManagement;
