import React from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

const { Option } = Select;

const PersonalInfoForm = ({ onNext, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);

  PersonalInfoForm.propTypes = {
    onNext: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
  };

  const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      callback();
    } else {
      callback("Please enter a valid email address");
    }
  };

  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          I. <span style={{ color: "#FF7201" }}>Personal</span> Information
        </h2>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              label="First Name:"
              name="firstName"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Middle Name:"
              name="middleName"
              rules={[
                { required: true, message: "Please enter your middle name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Last Name:"
              name="lastName"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Birth Date:"
              name="birthDate"
              rules={[
                { required: true, message: "Please enter your birth date" },
              ]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Email:"
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { validator: validateEmail },
              ]}
            >
              <Input type="email" name="email"/>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="SSS NO:"
              name="sssNum"
              rules={[
                { required: true, message: "Please enter your SSS number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"}>
          <Col span={17}>
            <Form.Item
              label="Complete Address:"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter your complete address",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="PHILHEALTH NO:"
              name="philHealthNum"
              rules={[
                {
                  required: true,
                  message: "Please enter your Philhealth number",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} justify={"space-between"}>
          <Col span={8}>
            <Form.Item
              label="Gender:"
              name="gender"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Contact NO:"
              name="contactNum"
              rules={[
                { required: true, message: "Please enter your contact number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Preference remarks:"
              name="preference"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="ex: Boyish" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"} gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Civil Status"
              name="civilStatus"
              rules={[
                { required: true, message: "Please enter your civil status" },
              ]}
            >
               <Select allowClear>
                <Option value="Single">Single</Option>
                <Option value="Married">Married</Option>
                <Option value="Widowed">Widowed</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="PAGIBIG NO:"
              name="pagIbigNum"
              rules={[
                { required: true, message: "Please enter your PAGIBIG number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="TIN NO:"
              name="tinNum"
              rules={[
                { required: true, message: "Please enter your TIN number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"}>
          <Col span={10}>
            <Form.Item
              label="In Case of Emergency"
              name="guardianName"
              rules={[{ required: true, message: "Please enter contact name" }]}
            >
              <Input placeholder="Contact Name" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Contact Number"
              name="guardianContactNum"
              rules={[
                { required: true, message: "Please enter contact number" },
              ]}
            >
              <Input placeholder="Contact No." type="number"/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Restriction Remarks:"
              name="restriction"
              rules={[
                {
                  required: true,
                  message: "Please enter your restriction remarks",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(PersonalInfoForm);
