import React from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

const { Option } = Select;

const PhysicalInfoForm = ({ onNext, onPrev, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);

  PhysicalInfoForm.propTypes = {
    onNext : PropTypes.func.isRequired,
    onPrev : PropTypes.func.isRequired,
    formData : PropTypes.object.isRequired,
  }
  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          I. <span style={{ color: "#FF7201" }}>Physical</span> Information
        </h2>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              name="withTattoo"
              label="With Tattoo:"
              rules={[
                {
                  required: true,
                  message: "Please choose",
                },
              ]}
            >
              <Select allowClear>
                <Option value="true">True</Option>
                <Option value="false">False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Remarks (location & if can be concealed):"
              name="remarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Teeth Remarks:"
              name="teethRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={4}>
            <Form.Item
              name="height"
              label="Height:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input type="number"/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="weight"
              label="Weight:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input type="number"/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Hair Color:"
              name="hairColor"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Type (Short, Medium, Long) with Remarks:"
              name="typeRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col>
            <Form.Item
              name="bodyFrame"
              label="Body Frame:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="shirtSize"
              label="Shirt Size:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select allowClear>
              <Option value="XS">XS</Option>
                <Option value="S">S</Option>
                <Option value="M">M</Option>
                <Option value="L">L</Option>
                <Option value="XL">XL</Option>
                <Option value="XXL">XXL</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Skin Complexion:"
              name="skinComplexion"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Eyesight Remarks (With Glasses/Contact Lens):"
              name="eyeSightRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="True">True</Option>
                <Option value="False">False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Physical Deformity Remarks:"
              name="physicalRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              label="Speech Remarks:"
              name="speechRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Dialect Spoken:"
              name="dialect"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Distinguishing Body Marks:"
              name="bodyMarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={onPrev}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(PhysicalInfoForm);
