import useSWR from "swr";

import { api } from "../services";

const BASE_URL = "/system-admin/departments";

export const useDepartment = (query = {}) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);
  const create = async (data) => {
    const department = await api.post(BASE_URL, data);
    return department;
  };

  const update = async (data) => {
    const department = await api.put(BASE_URL, data);
    return department;
  };
  const find = async (query) => {
    const department = await api.get(`${BASE_URL}/find?search=${query}`);
    return department;
  };
  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    update,
    find,
    mutate,
  };
};
export const useDepartmentById = (id) => {
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}/${id}`, api.get);

  const remove = async (id) => {
    const department = await api.delete(`${BASE_URL}/${id}`);
    return department;
  };
  return {
    isLoading,
    data,
    mutate,
    remove,
  };
};
