import { FilterOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import AttendanceFilterForm from "../../../common/AttendanceFilterForm";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";

const FilterBtn = () => {
  const {attendaceStore} = useStore();
  const {isModalOpen, setIsModalOpen} = attendaceStore;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        onClick={showModal}
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
      >
        <FilterOutlined />
        FILTER
      </Button>

      <Modal
        footer={null}
        closable={true}
        style={{ top: 110 }}
        width={900}
        open={isModalOpen}
        onCancel={handleCancel}
        className="view-all-modal-container"
        styles={{}}
        title="Filter"
      >
        <div>
          <AttendanceFilterForm />
        </div>
      </Modal>
    </>
  );
};

export default observer(FilterBtn);
