import React from "react";
import { Table, ConfigProvider } from "antd";
import { observer } from "mobx-react-lite";
import { useEmployee } from "../../../app/hooks/useEmployee";
import moment from "moment";

const ManpowerEmployeeList = () => {
  const { data } = useEmployee({
    pageNumber: 0,
    pageSize: 9999,
  });

  const manPowerEmployees = data?.filter(
    (employee) =>
      employee.serviceRecords?.[0]?.employmentStatus === "JobOrder" ||
      employee.serviceRecords?.[0]?.employmentStatus === "ProjectBased"
  );

  const columns = [
    {
      title: "Emp#",
      dataIndex: "employeeNumber",
      key: "employeeNumber",
      align: "center",
      render: (employeeNumber) => {
        return employeeNumber || "N/A";
      },
    },
    {
      title: "Employee Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (firstName) => {
        return `${firstName}` || "N/A";
      },
    },
    {
      title: "Position",
      key: "serviceRecords",
      dataIndex: "serviceRecords",
      align: "center",
      render: (serviceRecords) => {
        return `${serviceRecords?.[0]?.positionName}` || "N/A";
      },
    },
    {
      title: "Department",
      key: "serviceRecords",
      dataIndex: "serviceRecords",
      align: "center",
      render: (serviceRecords) => {
        return `${serviceRecords?.[0]?.departmentName}` || "N/A";
      },
    },
    {
      title: "Date Joined",
      key: "serviceRecords",
      dataIndex: "serviceRecords",
      align: "center",
      render: (serviceRecords) => {
        const dateJoined = serviceRecords?.[0]?.dateHired;
        return dateJoined ? moment(dateJoined).format("MMMM DD, YYYY") : "N/A";
      },
    },
    {
      title: "Employement Status",
      key: "serviceRecords",
      dataIndex: "serviceRecords",
      align: "center",
      render: (serviceRecords) => {
        return `${serviceRecords?.[0]?.employmentStatus}` || "N/A";
      },
    },
  ];
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            margin: 16,
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 8,
            padding: 25,
          },
          components: {
            Table: {
              // Seed Token
              headerBg: "#FF7201",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={manPowerEmployees}
          //loading={isLoading}
          style={{ width: "1284px" }}
        />
      </ConfigProvider>
    </>
  );
};

export default observer(ManpowerEmployeeList);
