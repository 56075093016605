import React from "react";
import { Card } from "antd";
import { StopOutlined, EllipsisOutlined } from "@ant-design/icons";
import Pic from "../../../app/assets/images/core.png";
import ProjectBasePic from "../../../app/assets/images/manpower-img.png";
import CoreDropdown from "../buttons/CoreDropdown";
import { observer } from "mobx-react-lite";
//import { useStore } from "../../../app/stores/store";
import ManPowerDropdown from "../buttons/ManPowerDropdown";
//import { useEmployee } from "../../../app/hooks/useEmployee";
import { useEmployeeCount } from "../../../app/hooks/useEmployeesCount";
//import { useDepartment } from "../../../app/hooks/useDepartment";

const EmployeeDashboard = () => {
  // const { employeeStore } = useStore();
  // const { manPowerEmployeeList } = employeeStore;
  //const coreLength = coreEmployeeList.length;
  // const manPowerLength = manPowerEmployeeList.length;
  const {currentCount} = useEmployeeCount();

 
  //const { data: departmentList } = useDepartment();

  // const regularEmployees = data?.filter(
  //   (employee) =>
  //     employee.employeeStatus === "Active" &&
  //     employee.serviceRecords?.[0]?.employmentStatus === "Regular"
  // );
  // const regularEmployeesLength = regularEmployees?.length || 0;

  // const projectBaseEmployees = data?.filter(
  //   (employee) =>
  //     employee.employeeStatus === "Active" &&
  //     employee.serviceRecords?.[0]?.employmentStatus === "ProjectBased"
  // );
  // const projectBaseEmployeesLength = projectBaseEmployees?.length || 0;

  // const jobOrderEmployees = data?.filter(
  //   (employee) =>
  //     employee.employeeStatus === "Active" &&
  //     employee.serviceRecords?.[0]?.employmentStatus === "JobOrder"
  // );
  // const jobOrderEmployeesLength = jobOrderEmployees?.length || 0;

  // const inactiveEmployees = data?.filter(
  //   (employee) =>
  //     employee.employeeStatus === "Inactive" ||
  //     employee.serviceRecords?.[0]?.employmentStatus === "Separated"
  // );
  // const inactiveEmployeesLength = inactiveEmployees?.length || 0;

  // const totalJO = projectBaseEmployeesLength + jobOrderEmployeesLength;
  return (
    <>
      <div className="employeeDashboardContainer">
        <Card className="employeeDashboardCard">
          <CoreDropdown />
          <div className="cardInfoDashboard">
            {/* temporary */}
            <img src={Pic} alt="Your Logo" className="icon2" />
            <div>
              <h1 className="cardNum">{currentCount?.core}</h1>
              <p className="cardDes">Core</p>
            </div>
          </div>
        </Card>

        <Card className="employeeDashboardCard">
          <ManPowerDropdown />
          <div className="cardInfoDashboard">
            {/* add icon */}
            <img src={ProjectBasePic} alt="Your Logo" className="icon2" />
            <div>
              <h1 className="cardNum">{currentCount?.projectBased}</h1>
              <p className="cardDes">Project Based</p>
            </div>
          </div>
        </Card>

        <Card className="employeeDashboardCard">
          <button className="modalBtn">
            <EllipsisOutlined className="dropdownIcon" />
          </button>
          <div className="cardInfoDashboard">
            <StopOutlined className="icon" />
            <div>
              <h1 className="cardNum">{currentCount?.inactive}</h1>
              <p className="cardDes">Inactive Employees</p>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default observer(EmployeeDashboard);
