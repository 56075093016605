import React, { useState, useEffect } from "react";
import { Table, ConfigProvider, Button } from "antd";
import { observer } from "mobx-react-lite";
import { useEmployeeShift } from "../../../app/hooks/useEmployeeShift";
import { getEmployeeById } from "../../../app/services/employee/getEmployeeById";
import { getShiftById } from "../../../app/services/shift/getShiftById";

const ShiftList = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading } = useEmployeeShift({
    pageNumber: pageNumber,
    pageSize: 10,
    sort: "date_descend"
  });
  const [employeeNames, setEmployeeNames] = useState({});
  const [employeeNumber, setEmployeeNumber] = useState({});
  const [employeePosition, setEmployeePosition] = useState({});
  const [employeeShift, setEmployeeShift] = useState({});

  useEffect(() => {
    const fetchEmployeeNames = async () => {
      const names = {};
      for (const shift of data) {
        try {
          const response = await getEmployeeById(shift.employeeId);
          if (response && response.firstName && response.lastName) {
            names[
              shift.employeeId
            ] = `${response.firstName} ${response.lastName}`;
          } else {
            //console.warn("Incomplete employee data:", response);
            names[shift.employeeId] = "Incomplete Data";
          }
        } catch (error) {
          //console.error("Error fetching employee:", error);
          names[shift.employeeId] = "Error Fetching Data";
        }
      }
      setEmployeeNames(names);
    };

    const fetchEmployeeNumber = async () => {
      const employeeNumber = {};
      for (const shift of data) {
        try {
          const response = await getEmployeeById(shift.employeeId);
          if (response && response.employeeNumber) {
            employeeNumber[shift.employeeId] = `${response.employeeNumber}`;
          } else {
            //console.warn("Incomplete employee data:", response);
            employeeNumber[shift.employeeId] = "Incomplete Data";
          }
        } catch (error) {
          //console.error("Error fetching employee:", error);
          employeeNumber[shift.employeeId] = "Error Fetching Data";
        }
      }
      setEmployeeNumber(employeeNumber);
    };

    const fetchEmployeePosition = async () => {
      const employeePosition = {};
      for (const shift of data) {
        try {
          const response = await getEmployeeById(shift.employeeId);
          if (response && response.serviceRecords[0]?.positionName) {
            employeePosition[
              shift.employeeId
            ] = `${response.serviceRecords[0]?.positionName}`;
          } else {
            //console.warn("Incomplete employee data:", response);
            employeePosition[shift.employeeId] = "Incomplete Data";
          }
        } catch (error) {
          //console.error("Error fetching employee:", error);
          employeePosition[shift.employeeId] = "Error Fetching Data";
        }
      }
      setEmployeePosition(employeePosition);
    };

    const fetchEmployeeShift = async () => {
      const employeeShift = {};
      for (const shift of data) {
        try {
          const response = await getShiftById(shift.shiftCodeId);
          if (response && response.description) {
            employeeShift[shift.shiftCodeId] = `${response.description}`;
          } else {
            //console.warn("Incomplete employee data:", response);
            employeeShift[shift.shiftCodeId] = "Incomplete Data";
          }
        } catch (error) {
          //console.error("Error fetching employee:", error);
          employeeShift[shift.shiftCodeId] = "Error Fetching Data";
        }
      }
      setEmployeeShift(employeeShift);
    };

    if (data && data.length > 0) {
      fetchEmployeeNames();
      fetchEmployeeNumber();
      fetchEmployeePosition();
      fetchEmployeeShift();
    }
  }, [data]);

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const columns = [
    {
      title: "Employee Number",
      dataIndex: "employeeId",
      key: "employeeId",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {employeeNumber[text] || "Loading..."}
        </p>
      ),
    },
    {
      title: "Employee Name",
      dataIndex: "employeeId",
      key: "employeeId",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {employeeNames[text] || "Loading..."}
        </p>
      ),
    },
    {
      title: "Level",
      dataIndex: "employeeId",
      key: "employeeId",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {employeePosition[text] || "Loading..."}
        </p>
      ),
    },
    {
      title: "Shift Schedule",
      dataIndex: "shiftCodeId",
      key: "shiftCodeId",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {employeeShift[text] || "Loading..."}
        </p>
      ),
    },
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            margin: 16,
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 8,
            padding: 25,
          },
          components: {
            Table: {
              headerBg: "#FF7201",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data || []} // Ensure data is not undefined
          loading={isLoading}
          style={{ width: "1284px" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            type="primary"
            onClick={handlePreviousPage}
            style={{ marginRight: "10px" }}
          >
            Previous Page
          </Button>
          <Button type="primary" onClick={handleNextPage}>
            Next Page
          </Button>
        </div>
      </ConfigProvider>
    </>
  );
};

export default observer(ShiftList);
