import { makeAutoObservable, runInAction } from "mobx";
import { getEmployeeByNumber } from "../services/userManagement/getEmployeeNumber";
import { toast } from "react-toastify";

export default class UserManagementStore {
  _data = [
    {
      id: 1,
      email: "super@super.com",
      roles: "Super Admin",
      status: "Active",
    },
    {
      id: 2,
      email: "admin@hr.com",
      roles: "Admin (HR)",
      status: "Active",
    },
    {
      id: 3,
      email: "accounting@accounting.com",
      roles: "Accounting",
      status: "Active",
    },
    {
      id: 4,
      email: "aaipm@aai.com",
      roles: "PM/AE",
      status: "Active",
    },
  ];

  userData = {};

  isLoading = false;
  isAddAccountModalVisible = false;
  editUser = null;

  isUpdateRoleFormVisible = false;

  constructor() {
    makeAutoObservable(this);
  }
  get getData() {
    return this._data;
  }

  setEditUser = (user) => {
    runInAction(() => {
      this.editUser = user;
    });
  };

  setLoading = (bool) => {
    runInAction(() => {
      this.isLoading = bool;
    });
  };

  setUpdateRoleFormVisibilty = (bool) => {
    runInAction(() => {
      this.isUpdateRoleFormVisible = bool;
    });
  };

  setData = (values) => {
    this.setLoading(true);

    runInAction(() => {
      const index = this._data.findIndex((user) => user.id === values.id);

      if (index !== -1) {
        // If user is found, update the data
        this._data[index] = values;
      } else {
        // If user is not found, add the new data
        this._data.push(values);
      }

      // Update the observable array to trigger reactivity
      this._data = [...this._data];

      this.setLoading(false);
    });
  };

  setIsModalVisible = (bool) => {
    runInAction(() => {
      this.isAddAccountModalVisible = bool;
    });
  };

  setGetEmployeeNumber = async (values) => {
    runInAction(async () => {
       await getEmployeeByNumber(values).then((response)=>{
        this.userData = { ...this.userData, ...response };
        this.setUpdateRoleFormVisibilty(true);
      }).catch((error)=>{
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data &&
          error.response.data.title
        ) {
          // Display the specific title error in the toast
          toast.error(error.response.data.title, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          // Extract and display all error titles in toasts
          error.response.data.errors.forEach((errorItem) => {
            toast.error(errorItem.title, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        } else {
          // Display a generic error message
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
     
    });
  };
}
