import React from 'react'
import { Button } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const LeaveBtn = () => {
  return (
    <>
      <Link to="/leave">
        <Button
          style={{
            background: "#FF7201",
            color: "#FFFFFF",
            borderRadius: "50px",
          }}

        >
          <FileTextOutlined />
          LEAVE
        </Button>
      </Link>
    </>

  )
}

export default LeaveBtn
