import React from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEmployee } from "../../../app/hooks/useEmployee";
import { toast } from "react-toastify";

const UpdateSalaryInfoForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  UpdateSalaryInfoForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  const { update } = useEmployee();
  const { updateEmployeeStore, modalStore } = useStore();
  const { personalInfoFormData, setPersonalInfo } = updateEmployeeStore;
  const {
    setUpdateEmployeeDetailsModalVisible,
    setUpdateSalaryInfoModalVisible,
  } = modalStore;

  const handleBack = () => {
    setUpdateEmployeeDetailsModalVisible(true);
    setUpdateSalaryInfoModalVisible(false);
  };

  const initialValues = {
    ...personalInfoFormData,
    basicPay:
      personalInfoFormData?.serviceRecords[0]?.employmentSalary.basicPay,
    netOfTax:
      personalInfoFormData?.serviceRecords[0]?.employmentSalary.netOfTax?.toString(),
    vatableAmount:
      personalInfoFormData?.serviceRecords[0]?.employmentSalary.vatableAmount,
    provision:
      personalInfoFormData?.serviceRecords[0]?.employmentSalary.addOnProvision,
    rate: personalInfoFormData?.serviceRecords[0]?.employmentSalary
      .approvedRateCard,
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();

      const updateFormData = {
        ...personalInfoFormData,
        serviceRecords: [
          {
            ...personalInfoFormData?.serviceRecords[0],
            employeeId: personalInfoFormData?.id,
            employmentSalary: {
              basicPay:
                formValues.basicPay ||
                personalInfoFormData?.serviceRecords[0]?.employmentSalary
                  .basicPay,
              netOfTax:
                Boolean(formValues.netOfTax) ||
                Boolean(personalInfoFormData?.serviceRecords[0]?.employmentSalary.netOfTax?.toString()),
              vatableAmount:
                formValues.vatableAmount ||
                personalInfoFormData?.serviceRecords[0]?.employmentSalary
                  .vatableAmount,
              addOnProvision:
                formValues.provision ||
                personalInfoFormData?.serviceRecords[0]?.employmentSalary
                  .addOnProvision,
              approvedRateCard:
                formValues.rate ||
                personalInfoFormData?.serviceRecords[0]?.employmentSalary
                  .approvedRateCard,
            },
          },
        ],
      };

      setPersonalInfo(updateFormData);

      await update(updateFormData)
        .then(() => {
          setUpdateEmployeeDetailsModalVisible(true);
          setUpdateSalaryInfoModalVisible(false);
          onSubmit();
          toast.success("Successfully Update Salary Information", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const { Option } = Select;

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <h2 className="core-form-title">
          III. <span style={{ color: "#FF7201" }}>Salary </span>Information
        </h2>
        <h5>Income</h5>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="basicPay"
              label="Basic Pay:"
              rules={[
                {
                  required: true,
                  message: "Require",
                },
              ]}
            >
              <Input type="number" placeholder="0" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Net of Tax:"
              name="netOfTax"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Vatable Amount:"
              name="vatableAmount"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="number" placeholder="0" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Add on Provision"
              name="provision"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="number" placeholder="0" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={7}>
            <Form.Item
              label="Approved Rate Card"
              name="rate"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="number" placeholder="Rate" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Update
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={handleBack}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(UpdateSalaryInfoForm);
