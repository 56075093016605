import React from "react";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import logo from "../../app/assets/images/third-logo.png";
import { login } from "../../app/services/auth";
import { toast } from "react-toastify";

function Login() {
  const onFinish = async (values) => {
    try {
      login(values)
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
          toast.error("Login failed! Invalid username or password", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      // .finally(() => {
      //   setLoading(false);
      // })
      // Redirect or perform any other action as needed
    } catch (error) {
      console.error("Login failed", error);
      // Handle login failure (show error message, etc.)
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Your profile" className="logo" />

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          // className="input"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
        >
          <Input
            className="input"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          //className="input"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input.Password
            className="input"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox className="text-remember">Remember me</Checkbox>
          </Form.Item>

          <a href="">Forgot Password</a>
        </Form.Item>

        <Form.Item>
          <Button type="text" htmlType="submit" className="login-form-button">
            <p className="btnText">Login</p>
          </Button>
          <p className="text">
            Don't have an Account? <a href="">Sign Up</a>
          </p>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
