import React from "react";
import { Row, Col, Layout } from "antd";
import AttendanceBody from "../../components/attendance/AttendanceBody";


const Attendance = () => {
  return(
    <>
     <Layout style={{ minHeight: "80vh", padding: "20px" }}>
        <Row justify={"center"}>
          <Col>
            <AttendanceBody/>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default Attendance;