import React from 'react';
import { format, subDays, isSameMonth } from 'date-fns'; // Using date-fns for date manipulation
import { observer } from "mobx-react-lite";
import { useStore } from '../../app/stores/store';

const AttendanceHistoryCard = () => {
  const {attendaceStore} = useStore();
  const { setDate} = attendaceStore;
  const currentDate = new Date(); // Get current date
  const monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // Get the start date of the current month
  const dates = [];

  // Loop through previous dates of the current month
  for (let i = currentDate.getDate() - 1; i >= 1; i--) {
    const previousDate = subDays(currentDate, i);
    if (isSameMonth(previousDate, monthStartDate)) {
      dates.push(previousDate);
    } else {
      break; // Break the loop if the month changes
    }
  }

  const handleClick = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setDate(formattedDate);
    // Here you can handle what to do with the clicked date value
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto mt-1">
      <div className="px-6 py-4" style={{backgroundColor: '#EDEDED'}}>
        <div className="font-bold text-xl mb-2">Attendance History</div>
        {dates.map((date, index) => (
          <div key={index} className="text-gray-700 text-base" onClick={() => handleClick(date)} style={{cursor: 'pointer'}}>
            {format(date, 'EEEE, MMMM d, yyyy')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(AttendanceHistoryCard);