import React from "react";
import { Space, Table, ConfigProvider, Button, Card } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useUserManagement } from "../../app/hooks/useUserManagement";
import { useCurrentUser } from "../../app/hooks/useCurrentUser";

const { Meta } = Card;

const UserList = () => {
  const { userManagementStore } = useStore();
  const { setIsModalVisible, setGetEmployeeNumber } = userManagementStore;
  const { data, isLoading } = useUserManagement();
  const { currentUser, role } = useCurrentUser();

  const handleEdit = (record) => {
    setIsModalVisible(true);
    setGetEmployeeNumber(record.employeeNumber);
  };

  const columns = [
    {
      title: "USER NAME",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "ROLES",
      dataIndex: "roles",
      key: "roles",
      align: "center",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {Array.isArray(text) ? text.join(", ") : text}
        </p>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (text) => (
        <p style={{ color: "green", fontSize: 17 }}>
          {text === 1 ? "Active" : "Inactive"}
        </p>
      ),
      align: "center",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ backgroundColor: "#F78D2E", color: "white" }}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            margin: 16,
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 8,
            padding: 25,
          },
          components: {
            Table: {
              // Seed Token
              headerBg: "#2C2C2C",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
        {role.includes("Project Manager", "Employee") ? (
          <Card
            hoverable
            style={{ width: 240 }}
            cover={
              <img
                alt="example"
                src={
                  currentUser?.user?.profileImagePath ||
                  "https://picsum.photos/200/300"
                }
              />
            }
          >
            <Meta
              title={`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}
              description={`${currentUser?.user?.email}`}
            />
          </Card>
        ) : (
          <Table
            rowKey="userId"
            columns={columns}
            dataSource={data}
            loading={isLoading}
            style={{ width: "1284px" }}
          />
        )}
      </ConfigProvider>
    </>
  );
};

export default observer(UserList);
