import React from 'react'
import { Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

const LeaveFilterBtn = () => {
  return (
    <>
      <Button
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
        onClick={()=>alert('Under Development')}
      >
        <FilterOutlined />
        FILTER
      </Button>
    </>

  )
}

export default LeaveFilterBtn
