import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ViewAll = () => {
  return (
    <>
      <Link to="/attendance">
        <Button
          style={{
            background: "#FF7201",
            color: "#FFFFFF",
            borderRadius: "50px",
            margin: "0 0 10px 0",
          }}
        >
          View All
        </Button>
      </Link>
    </>
  );
};

export default ViewAll;
