import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col, Space, Modal } from "antd";
import { useStore } from "../../app/stores/store";
import FilterBtn from "../dashboard/attendance-gallery/buttons/FilterBtn";
import UploadBtn from "../dashboard/attendance-gallery/buttons/UploadBtn";
import ShiftsBtn from "../dashboard/attendance-gallery/buttons/ShiftsBtn";
import AttendanceList from "./AttendanceList";
import TimeCard from "./TimeCard";
import AttendanceHistoryCard from "./AttendanceHistory";
import AttendanceGallery from "../common/AttendanceGallery";
import LeaveBtn from "../dashboard/attendance-gallery/buttons/LeaveBtn";
import ExportBtn from "../dashboard/attendance-gallery/buttons/ExportBtn";

const AttendanceBody = () => {
  const { attendaceStore } = useStore();
  const { isAttendanceModal, setIsAttendanceModalOpen } = attendaceStore;
  const handleShiftCancel = () => {
    setIsAttendanceModalOpen(false);
  };
  return (
    <>
      <Row>
        <Col span={22}>
          <Row justify={"space-between"}>
            <Col style={{ marginLeft: "5%" }}>
              <h1>Attendance</h1>
            </Col>
            <Col>
              <Space style={{ marginTop: 20, marginLeft: "10%" }}>
                <ExportBtn />
                <LeaveBtn />
                <ShiftsBtn />
                <UploadBtn />
                <FilterBtn />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-evenly"}>
            <Col>
              <AttendanceList />
            </Col>
            <Col>
              <Col span={24}>
                <TimeCard />
              </Col>
              <Col style={{ marginTop: 20 }}>
                <AttendanceHistoryCard />
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        footer={null}
        style={{ top: 110 }}
        width={900}
        open={isAttendanceModal}
        onCancel={handleShiftCancel}
        destroyOnClose
      >
        <Row justify={"center"}>
          <Col>
            <h3>
              ATTENDANCE <span style={{ color: "#FF7201" }}>GALLERY</span>
            </h3>
          </Col>
        </Row>

        <AttendanceGallery />
      </Modal>
    </>
  );
};

export default observer(AttendanceBody);
