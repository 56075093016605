import { FileAddOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Col, Divider } from "antd";
import React from "react";
import ShiftList from "../../tables/ShiftList";
import ShiftForm from "../../../attendance/ShiftForm";
import EmployeeShiftForm from "../../../attendance/EmployeeShiftForm";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";

const ShiftsBtn = () => {
  const { shiftStore } = useStore();
  const {
    isModalOpen,
    setIsModalOpen,
    createShiftModal,
    setCreateShiftModalVisible,
    createEmployeeShift,
    setCreateEmployeeShiftModalVisible
  } = shiftStore;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showCreateShiftModal = () => {
    setCreateShiftModalVisible(true);
    setIsModalOpen(false);
  };

  const handleShiftCancel = () => {
    setCreateShiftModalVisible(false);
  };


  const showCreateEmployeeShiftModal = () => {
    setCreateEmployeeShiftModalVisible(true);
    setIsModalOpen(false);
  };

  const handleCreateEmployeeShiftCancel = () => {
    setCreateEmployeeShiftModalVisible(false);
  };

  return (
    <>
      <Button
        onClick={showModal}
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
      >
        <FileAddOutlined />
        SHIFTS
      </Button>
      <Modal
        footer={null}
        style={{ top: 110 }}
        closable={false}
        width={1000}
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Row justify={"space-between"}>
          <Col>
            <h3>
              <span style={{ color: "#FF7201" }}>SHIFTS</span>
            </h3>
          </Col>
          <Col>
            <Row gutter={20}>
              <Col>
                <Button
                  onClick={showCreateEmployeeShiftModal}
                  style={{
                    background: "#FF7201",
                    color: "#FFFFFF",
                    borderRadius: "50px",
                    marginTop: 15,
                  }}
                >
                  <FileAddOutlined />
                  CREATE EMPLOYEE SHIFT
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={showCreateShiftModal}
                  style={{
                    background: "#FF7201",
                    color: "#FFFFFF",
                    borderRadius: "50px",
                    marginTop: 15,
                  }}
                >
                  <FileAddOutlined />
                  CREATE SHIFT
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <ShiftList />
      </Modal>

      <Modal
        footer={null}
        style={{ top: 110, zIndex: 999 }}
        width={900}
        open={createShiftModal}
        onCancel={handleShiftCancel}
        destroyOnClose
      >
        <Row justify={"space-between"}>
          <Col>
            <h3>
              CREATE NEW <span style={{ color: "#FF7201" }}>SHIFT</span>
            </h3>
          </Col>
        </Row>
        <Divider />
        <ShiftForm />
      </Modal>

      <Modal
        footer={null}
        style={{ top: 110 }}
        width={900}
        open={createEmployeeShift}
        onCancel={handleCreateEmployeeShiftCancel}
        destroyOnClose
      >
        <Row justify={"space-between"}>
          <Col>
            <h3>
              CREATE NEW <span style={{ color: "#FF7201" }}>EMPLOYEE SHIFT</span>
            </h3>
          </Col>
        </Row>
        <Divider />
        <EmployeeShiftForm/>
      </Modal>

    </>
  );
};

export default observer(ShiftsBtn);
