import useSWR from "swr";

import { api } from "../services";

const BASE_URL = "/web-user/employees/birthday";

export const useCurrentBirthday = () => {
  const { data, isLoading, mutate } = useSWR(BASE_URL, api.get);

  return {
    isLoading,
    currentDate: data,
    data,
    mutate,
  };
}