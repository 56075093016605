import { Space } from "antd";
import EmployeeAttendanceCard from "./cards/EmployeeAttendanceCard";
import UploadBtn from "./buttons/UploadBtn";
import FilterBtn from "./buttons/FilterBtn";
import ViewAll from "./buttons/ViewAll";

const AttendanceGallery = () => {
  return (
    <div className="attendance-gallery-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
        }}
      >
        <h3>Attendance Gallery</h3>
        <Space>
          <UploadBtn />
          <FilterBtn />
        </Space>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <EmployeeAttendanceCard />
        <ViewAll />
      </div>
    </div>
  );
};

export default AttendanceGallery;
