import React, { useState } from "react";
import { Dropdown, Modal } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import ManPowerEmployeeList from "../list/ManPowerEmployeeList";

const ManPowerDropdown = () => {
  const [isShortTermModalVisible, setIsShortTermModalVisible] = useState(false);
  const [isManPowerModalVisible, setIsManPowerModalVisible] = useState(false);

  const showShortTermModal = () => {
    setIsShortTermModalVisible(true);
  };

  const handleCancel = () => {
    setIsShortTermModalVisible(false);
    setIsManPowerModalVisible(false)
  };

  const showManPowerModal = () => {
    setIsManPowerModalVisible(true);
  }; 

  const items = [
    {
      label: <p onClick={showShortTermModal}>SHORT TERM</p>,
    },
    {
      type: "divider",
    },
    {
      label: <p onClick={showManPowerModal}>LONG TERM</p>,
    },
    {
      type: "divider",
    },
    {
      label: <p>INACTIVE</p>,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} className="dropdownBtn" trigger={["click"]}>
        <button className="modalBtn">
          <EllipsisOutlined className="dropdownIcon" />
        </button>
      </Dropdown>
      <Modal
        open={isShortTermModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <h3 className="employee-modal-title">
          Short Term
        </h3>
        <ManPowerEmployeeList/>
      </Modal>
      <Modal
        open={isManPowerModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <h3 className="employee-modal-title">
          Long Term
        </h3>
        <ManPowerEmployeeList/>
      </Modal>
    </>
  );
};

export default ManPowerDropdown;
