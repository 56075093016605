import React from "react";
import { Form, Input, Button, Row, Col, Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useRoles } from "../../../app/hooks/useRoles";
import { useUserManagement } from "../../../app/hooks/useUserManagement";
import { toast } from "react-toastify";


const AddAccountForm = () => {
  const [form] = Form.useForm();
  const {userManagementStore} = useStore();
  const { userData, setIsModalVisible, setUpdateRoleFormVisibilty} = userManagementStore;
  const { data} =useRoles();
  const {update,mutate} = useUserManagement();

   const initialValues = {
     ...userData,
   }

   const handleCancel = () => {
    setIsModalVisible(false);
    setUpdateRoleFormVisibilty(false);
   }

  const handleSubmit = async (values) => {
    try {
      const formValues = form.getFieldsValue();

      const updatedFormData = {
        userId: userData?.userId,
        roles: formValues.roles
      }
      if (values.roles != null) {
        await update(updatedFormData).then(()=>{
          mutate();
          setIsModalVisible(false);
          toast.success("Successfully Update User Role", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
      }  else if (values.roles == null) {
        toast.error("Please Select User Roles", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
    
  };

 

  return (
    <>
      <Form form={form} initialValues={initialValues} layout="vertical" style={{ marginTop: 40 }} onFinish={handleSubmit}>
        <Row justify={"space-between"}>
          <Col span={10}>
            <Form.Item
              label="Name:"
              name="firstName"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input disabled={true}/>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Email:"
              name="email"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="email" disabled={true}/>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Employee ID:"
              name="id"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input disabled={true}/>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Status:"
              name="employeeStatus"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input disabled={true}/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col span={10}>
            <Form.Item
              label="Roles:"
              name="roles"
              rules={[{ required: true, message: "Required" }]}
            >
              <Checkbox.Group options={data?.map((item)=>{
                 return {
                  value: item.name,
                  label: item.name,
                };
              })}/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(AddAccountForm);
