import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import CountdownTimerCard from "./CountDownTimeCard";

const Events = () => {
  const { id } = useParams();
  const { eventsStore } = useStore();
  const { setGetEventsId, eventData } = eventsStore;

  const calculateCountdown = (eventDate) => {
    const now = new Date().getTime();
    const eventTime = new Date(eventDate).getTime();
    const difference = eventTime - now;

    if (difference <= 0) {
      // Event has already occurred
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [countdown, setCountdown] = useState(
    calculateCountdown(eventData.eventDate)
  );

  useEffect(() => {
    setGetEventsId(id);
    console.log(eventData.imagePath)
    const intervalId = setInterval(() => {
      setCountdown(calculateCountdown(eventData.eventDate));
    }, 1000);

    return () => {
      setGetEventsId("");
      clearInterval(intervalId);
    };
  }, [id, setGetEventsId, eventData.eventDate]);

  const backgroundImageUrl = eventData.imagePath || "";

  return (
    <>
      <div
        className="container mx-auto flex items-center justify-center"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
        }}
      >
        <div className="text-center text-white">
          <h2 className="text-5xl font-bold mb-4 uppercase">
            {eventData.name}
          </h2>
        </div>
      </div>
      <div className="lg:flex lg:justify-between p-20 mt-10">
        <div className="lg:max-w-[200px]">
          <CountdownTimerCard countdown={countdown} />
        </div>
        <div className="lg:max-w-[1000px]">
          <h2>What is {eventData.name}?</h2>
          <p>{eventData.description}</p>
        </div>
      </div>
    </>
  );
};

export default observer(Events);
