import React from "react";
import { Row, Col } from "antd";
import EventRequestList from "./EventRequestList";
import CreateEventButton from "./button/CreateEventButton";
import { useCurrentUser } from "../../app/hooks/useCurrentUser";

const EventRequestBody = () => {
  const { role } = useCurrentUser();
  return (
    <>
      <Row>
        <Col span={22}>
          <Row justify={"end"}>
            {role.includes("Project Manager") ? <CreateEventButton /> : null}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"center"}>
            <EventRequestList />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EventRequestBody;
