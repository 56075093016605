import React from "react";
import { Row, Col } from "antd";
import UserList from "./UserList";
import AddAccountButton from "./button/AddAccountButton";
import { useCurrentUser } from "../../app/hooks/useCurrentUser";

const UserManagementBody = () => {
  const { role } = useCurrentUser();
  return (
    <>
      <Row>
        <Col span={22}>
          <Row justify={"end"}>
            {role.includes("Super Admin") ? <AddAccountButton /> : null}
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"center"}>
            <UserList /> 
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserManagementBody;
