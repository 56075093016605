import useSWR from "swr";

import { api } from "../services";


const BASE_URL = "/super-admin/roles";

export const useRoles = () => {
  const { data, isLoading, mutate } = useSWR(BASE_URL, api.get);
  const create = async (data) => {
    const positions = await api.post(BASE_URL, data);
    return positions;
  };
  const update = async (data) => {
    const positions = await api.put(BASE_URL, data);
    return positions;
  };
  const find = async (query) => {
    const positions = await api.get(`${BASE_URL}/find?search=${query}`);
    return positions;
  };
  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    update,
    find,
    mutate,
  };
};