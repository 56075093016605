import useSWR from "swr";
import { api } from "../services";

const BASE_URL = "/system-admin/shift-codes";

export const useShift = (query = {}) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);

  const create = async (data) => {
    const shift = await api.post(BASE_URL, data);
    return shift;
  };

  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    mutate,
  };
};