import React, { useState } from "react";
import { Card, Dropdown, Modal } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
//import { useEmployee } from "../../../app/hooks/useEmployee";
import Pic from "../../../app/assets/images/core.png";
//import ModalTable from "./list/ModalTable";
import CoreEmployeeList from "../../employees/list/CoreEmployeeList";
import ManPowerEmployeeList from "../../employees/list/ManPowerEmployeeList";
import PropTypes from "prop-types";

const TotalEmployees = (props) => {
  TotalEmployees.propTypes = {
    count: PropTypes.array.isRequired,
  };

  const { count: totalEmployees } = props;

  const [isCoreModalVisible, setIsCoreModalVisible] = useState(false);
  const [isProjectBasedModalVisible, setIsProjectBasedModalVisible] =
    useState(false);

  const showCoreModal = () => {
    setIsCoreModalVisible(true);
  };

  const handleCancel = () => {
    setIsCoreModalVisible(false);
    setIsProjectBasedModalVisible(false);
  };

  const showProjectBasedModal = () => {
    setIsProjectBasedModalVisible(true);
  };

  const items = [
    {
      label: <p onClick={showCoreModal}>CORE</p>,
    },
    {
      type: "divider",
    },
    {
      label: <p onClick={showProjectBasedModal}>PROJECT BASED</p>,
    },
  ];

  //const coreEmployeeList = [];
  //const projectEmployeesList = [];

  return (
    <Card className="summary-count-card">
      <Dropdown menu={{ items }} className="dropdown-btn" trigger={["click"]}>
        <button className="modal-btn">
          <EllipsisOutlined className="dropdownIcon" />
        </button>
      </Dropdown>
      <Modal
        open={isCoreModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <h3 className="summary-modal-title">Core</h3>
        <CoreEmployeeList />
      </Modal>
      <Modal
        open={isProjectBasedModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <h3 className="summary-modal-title">Project Based</h3>
        <ManPowerEmployeeList />
      </Modal>

      <div className="summary-count-card">
        {/* temporary */}
        <img src={Pic} alt="Your Logo" className="icon2" />
        <div>
          <h1 className="cardNum">{totalEmployees}</h1>
          <p className="cardDes">Total Employees</p>
        </div>
      </div>
    </Card>
  );
};

export default TotalEmployees;
