import { makeAutoObservable, runInAction} from "mobx";

export default class AttendanceStore {
  startDate = '';
  endDate = '';
  employeeId = '';
  employeeNumber = '';

  isModalOpen = false;
  isExportModalOpen = false;

  attendaceIndexObj = {};

  isAttendanceModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setDateFilter = (startdate, endDate, employeeId, employeeNumber) => {
    runInAction(() => {
      this.startDate = startdate;
      this.endDate = endDate;
      this.employeeId = employeeId;
      this.employeeNumber = employeeNumber
    });
  };

  setIsModalOpen = (bool) => {
    runInAction(() => {
      this.isModalOpen = bool;
    });
  };
  setIsExportModalOpen = (bool) => {
    runInAction(() => {
      this.isExportModalOpen = bool;
    });
  };

  setDate = (date) => {
    runInAction(() => {
      this.startDate = date;
      this.endDate = date;
    });
  }

  setAttendaceIndexObj = (data) => {
    runInAction(() => {
      this.attendaceIndexObj = {...this.attendaceIndexObj, ...data};
    });
  }

  setIsAttendanceModalOpen = (bool) => {
    runInAction(() => {
      this.isAttendanceModal = bool;
    });
  }

}