import React from "react";
import { Button } from "antd";
import { BellOutlined } from "@ant-design/icons";

const NotifyButton = () => {
  return (
    <>
      <Button className="notify-button">
        <BellOutlined />
        NOTIFY
      </Button>
    </>
  );
};

export default NotifyButton;
