import React from 'react'
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const ExportBtn = () => {
  return (
    <>
      <Button
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
        onClick={()=>alert('Under Development')}
      >
        <DownloadOutlined />
        EXPORT
      </Button>
    </>

  )
}

export default ExportBtn
