import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'DAILY',
      color: "white",
      font: {
        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        size: 23,
      },
    },
  },
  scales: {
    x: {
      title: {
        color: "white", // X-axis label color
      },
      ticks: {
        color: "white", // X-axis ticks color
      },
    },
    y: {
      title: {
        color: "white", // Y-axis label color
      },
      ticks: {
        color: "white", // Y-axis ticks color
      },
    },
  },
};

const labels = ['January 1','January 2','January 3','January 4','January 5','January 6','January 7','January 8',];


export const data = {
  labels,
  datasets: [
    {
      label: '',
      data: [80, 120, 160, 140, 200, 180, 250, 10],
      backgroundColor: 'white',
    },
  ],
};


const DailyAttendance = () => {
  return (
    <div className="daily-attendance-container">
      <Bar options={options} data={data} />
    </div>
  );
};

export default DailyAttendance;
