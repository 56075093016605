import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

const SalaryDetailForm = ({ onNext, onPrev, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);

  SalaryDetailForm.propTypes = {
    onNext : PropTypes.func.isRequired,
    onPrev : PropTypes.func.isRequired,
    formData : PropTypes.object.isRequired,
  }
  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          III. <span style={{ color: "#FF7201" }}>Salary </span>Details
        </h2>
        <h5>Income</h5>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="incomeProvisions"
              label="Add On Provisions:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Approved Rate Card:"
              name="incomeRateCard"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h5>Deductions (Company)</h5>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="suspension"
              label="SUSPENSION:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Accountability:"
              name="accountability"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h5>Government Mandates</h5>
        <Row justify={"space-between"} gutter={20}>
          <Col>
            <Form.Item
              name="governSSSRemi"
              label="SSS Remittance:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="PAGIBIG Remittance:"
              name="govenrPagIbigRemi"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Philhealth Remittance:"
              name="governPhilHealthRemi"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={onPrev}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(SalaryDetailForm);
