import React, { useState } from "react";
import { Table, Button } from "antd";
import ViewRequestBtn from "./buttons/ViewRequestBtn";
import { useLeaveApplication } from "../../../app/hooks/useLeaveApplications";
import moment from "moment";

const RequestByAdmin = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading, mutate } = useLeaveApplication({
    pageNumber: pageNumber,
    pageSize: 3,
    sort: "date_descend",
  });

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: ["startDate", "endDate"],
      key: "requestDate",
      align: "center",
      render: (text, record) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {moment(record.startDate).format("M/D/YY")} -{" "}
          {moment(record.endDate).format("M/D/YY")}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: "Application",
      dataIndex: "leaveTypeName",
      key: "leaveTypeName",
    },
    {
      title: "Status",
      key: "statusDesc",
      dataIndex: "statusDesc",
      render: (text) => {
        let color;
        switch (text) {
          case "ForApproval":
            color = "#CFC700";
            break;
          case "Approved":
            color = "#008C27";
            break;
          case "Rejected":
            color = "#FF0000";
            break;
          default:
            color = "black";
        }
        return <p style={{ color, fontSize: 17 }}>{text}</p>;
      },
      align: "center",
    },
    {
      title: "",
      key: "record",
      render: (record) => (
        <ViewRequestBtn
          record={record}
          onSubmit={() => {
            mutate();
          }}
        />
      ),
      align: "center",
    },
  ];
  return (
    <div
      style={{
        width: "1240px",
        margin: "20px",
        padding: "0 30px 30px 30px",
        backgroundColor: "#EFEEEE",
        borderRadius: "20px",
        border: "4px solid #e6e6e6",
      }}
    >
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        loading={isLoading}
        title={() => (
          <h2 style={{ textAlign: "center" }}>Request By Admin (HR)</h2>
        )}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={handlePreviousPage}
          style={{ marginRight: "10px" }}
        >
          Previous Page
        </Button>
        <Button type="primary" onClick={handleNextPage}>
          Next Page
        </Button>
      </div>
    </div>
  );
};

export default RequestByAdmin;
