import useSWR from "swr";

import { api } from "../services";

const BASE_URL = "/system-admin/regions";

export const useRegions = () => {
  const { data, isLoading, mutate } = useSWR(BASE_URL, api.get);
  const create = async (data) => {
    const regions = await api.post(BASE_URL, data);
    return regions;
  };
  const update = async (data) => {
    const regions = await api.put(BASE_URL, data);
    return regions;
  };
  const find = async (query) => {
    const regions = await api.get(`${BASE_URL}/find?search=${query}`);
    return regions;
  };
  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    update,
    find,
    mutate,
  };
};

export const useRegionsById = (id) => {
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}/${id}`, api.get);

  const remove = async (id) => {
    const regions = await api.delete(`${BASE_URL}/${id}`);
    return regions;
  };
  return {
    isLoading,
    data,
    mutate,
    remove,
  };
};
