import React from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useStore } from "../../../app/stores/store";
import { useEmployee } from "../../../app/hooks/useEmployee";
import { toast } from "react-toastify";

const { Option } = Select;

const UpdatePhysicalInfoForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  UpdatePhysicalInfoForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  const { update } = useEmployee();
  const { updateEmployeeStore, modalStore } = useStore();
  const { personalInfoFormData, setPersonalInfo } = updateEmployeeStore;
  const {
    setUpdateEmployeeDetailsModalVisible,
    setUpdatePhysicalInfoModalVisible,
  } = modalStore;

  const handleBack = () => {
    setUpdateEmployeeDetailsModalVisible(true);
    setUpdatePhysicalInfoModalVisible(false);
  };

  const initialValues = {
    ...personalInfoFormData,
    height: personalInfoFormData?.employeePhysicalProfile?.height,
    weight: personalInfoFormData?.employeePhysicalProfile?.weight,
    withTattoo: Boolean(
      personalInfoFormData?.employeePhysicalProfile?.withTattoo
    ),
    remarks: personalInfoFormData?.employeePhysicalProfile?.tattooRemarks,
    teethRemarks: personalInfoFormData?.employeePhysicalProfile?.teethRemarks,
    hairColor: personalInfoFormData?.employeePhysicalProfile?.hairColor,
    typeRemarks: personalInfoFormData?.employeePhysicalProfile?.hairType,
    bodyFrame: personalInfoFormData?.employeePhysicalProfile?.bodyFrame,
    shirtSize: personalInfoFormData?.employeePhysicalProfile?.shirtSize,
    skinComplexion:
      personalInfoFormData?.employeePhysicalProfile?.skinComplexion,
    eyeSightRemarks: Boolean(
      personalInfoFormData?.employeePhysicalProfile?.isPoorEyesight
    ),
    physicalRemarks:
      personalInfoFormData?.employeePhysicalProfile?.physicalDeformityRemarks,
    speechRemarks: personalInfoFormData?.employeePhysicalProfile?.speechRemarks,
    dialect: personalInfoFormData?.employeePersonalProfile?.dialectSpoken,
    bodyMarks:
      personalInfoFormData?.employeePhysicalProfile?.distinguishingBodyMarks,
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();

      const updateFormData = {
        ...personalInfoFormData,
        // employeePersonalProfile: {
        //   dialectSpoken:
        //     formValues.dialect ||
        //     personalInfoFormData.employeePersonalProfile.dialectSpoken,
        // },
        employeePhysicalProfile: {
          employeeId : personalInfoFormData?.id,
          height:
            formValues.height ||
            personalInfoFormData.employeePhysicalProfile.height,
          weight:
            formValues.weight ||
            personalInfoFormData.employeePhysicalProfile.weight,
          withTattoo:
            formValues.withTattoo ||
            personalInfoFormData.employeePhysicalProfile.withTattoo,
          teethRemarks:
            formValues.teethRemarks ||
            personalInfoFormData?.employeePhysicalProfile?.teethRemarks,
          tattooRemarks:
            formValues.remarks ||
            personalInfoFormData.employeePhysicalProfile.tattooRemarks,
          hairColor:
            formValues.hairColor ||
            personalInfoFormData.employeePhysicalProfile.hairColor,
          hairType:
            formValues.typeRemarks ||
            personalInfoFormData.employeePhysicalProfile.hairType,
          bodyFrame:
            formValues.bodyFrame ||
            personalInfoFormData.employeePhysicalProfile.bodyFrame,
          shirtSize:
            formValues.shirtSize ||
            personalInfoFormData.employeePhysicalProfile.shirtSize,
          skinComplexion:
            formValues.skinComplexion ||
            personalInfoFormData.employeePhysicalProfile.skinComplexion,
          isPoorEyesight:
            formValues.eyeSightRemarks ||
            personalInfoFormData.employeePhysicalProfile.isPoorEyesight,
          physicalDeformityRemarks:
            formValues.physicalRemarks ||
            personalInfoFormData.employeePhysicalProfile
              .physicalDeformityRemarks,
          speechRemarks:
            formValues.speechRemarks ||
            personalInfoFormData.employeePhysicalProfile.speechRemarks,
          distinguishingBodyMarks:
            formValues.bodyMarks ||
            personalInfoFormData.employeePhysicalProfile
              .distinguishingBodyMarks,
        },
      };

      setPersonalInfo(updateFormData);

      await update(updateFormData)
        .then(() => {
          setUpdateEmployeeDetailsModalVisible(true);
          setUpdatePhysicalInfoModalVisible(false);
          onSubmit();
          toast.success("Successfully Update Physical Information", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <h2 className="core-form-title">
          I. <span style={{ color: "#FF7201" }}>Physical</span> Information
        </h2>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              name="withTattoo"
              label="With Tattoo:"
              rules={[
                {
                  required: true,
                  message: "Please choose",
                },
              ]}
            >
              <Select allowClear defaultValue={initialValues.withTattoo}>
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Remarks (location & if can be concealed):"
              name="remarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Teeth Remarks:"
              name="teethRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={4}>
            <Form.Item
              name="height"
              label="Height:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="weight"
              label="Weight:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Hair Color:"
              name="hairColor"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Type (Short, Medium, Long) with Remarks:"
              name="typeRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col>
            <Form.Item
              name="bodyFrame"
              label="Body Frame:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="shirtSize"
              label="Shirt Size:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select allowClear>
                <Option value="XS">XS</Option>
                <Option value="S">S</Option>
                <Option value="M">M</Option>
                <Option value="L">L</Option>
                <Option value="XL">XL</Option>
                <Option value="XXL">XXL</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Skin Complexion:"
              name="skinComplexion"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Eyesight Remarks (With Glasses/Contact Lens):"
              name="eyeSightRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear defaultValue={initialValues.eyeSightRemarks}>
                <Option value={true}>True</Option>
                <Option value={false}>False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Physical Deformity Remarks:"
              name="physicalRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              label="Speech Remarks:"
              name="speechRemarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Dialect Spoken:"
              name="dialect"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Distinguishing Body Marks:"
              name="bodyMarks"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Update
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={handleBack}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(UpdatePhysicalInfoForm);
