import { makeAutoObservable, runInAction } from "mobx";
import { getShiftById } from "../services/shift/getShiftById";

export default class ShiftStore {
  isModalOpen = false;
  createShiftModal = false;
  remarks = "";
  check = true;
  checkboxState = {
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  };

  // timePickerModalVisible = false;
  timeIn = null;
  timeOut = null;

  createEmployeeShift = false;

  shiftCodeData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setIsModalOpen = (bool) => {
    runInAction(() => {
      this.isModalOpen = bool;
    });
  };

  setCreateShiftModalVisible = (bool) => {
    runInAction(() => {
      this.createShiftModal = bool;
    });
  };

  setTimes = (timeIn, timeOut) => {
    runInAction(() => {
      this.timeIn = timeIn;
      this.timeOut = timeOut;
    });
  };

  setRemarks = (data) => {
    runInAction(() => {
      this.remarks = data;
    });
  };
  // setIsTimePickerModalVisible = (bool) => {
  //   runInAction(() => {
  //     this.timePickerModalVisible = bool;
  //   })
  // }

  setCreateEmployeeShiftModalVisible = (bool) => {
    runInAction(() => {
      this.createEmployeeShift = bool;
      console.log(this.createEmployeeShift);
    });
  };

  setGetShiftById = async (id) => {
    runInAction(async () => {
      const response = await getShiftById(id);
      this.shiftCodeData = { ...this.shiftCodeData, ...response };
      this.setRemarks(this.shiftCodeData.remarks);
    });
  };

  setCheckboxState = (e, value) => {
    runInAction(() => {
      this.checkboxState = { ...this.checkboxState, [value]: e.target };
    });
  };

  setCheck = (bool) => {
    runInAction(() => {
      this.check = bool;
    });
  }

}
