import React from "react";
import PropTypes from "prop-types";

const CountdownTimerCard = ({ countdown }) => {
  return (
    <div
      className="bg-black p-4 rounded-md shadow-md lg:w-[300px]"
      style={{ borderRadius: 26 }}
    >
      <h2 className="text-xl font-semibold mb-4 text-center text-white">
        Time Remaining
      </h2>
      <div className="flex items-center justify-center space-x-4">
        <div className="flex flex-col items-center">
          <span className="text-3xl font-bold text-white">
            {countdown.days}
          </span>
          <span className="text-gray-500 text-white">Days</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl font-bold text-white">
            {countdown.hours}
          </span>
          <span className="text-gray-500 text-white">Hours</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl font-bold text-white">
            {countdown.minutes}
          </span>
          <span className="text-gray-500 text-white">Minutes</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl font-bold text-white">
            {countdown.seconds}
          </span>
          <span className="text-gray-500 text-white">Seconds</span>
        </div>
      </div>
    </div>
  );
};

CountdownTimerCard.propTypes = {
  countdown: PropTypes.shape({
    days: PropTypes.number.isRequired,
    hours: PropTypes.number.isRequired,
    minutes: PropTypes.number.isRequired,
    seconds: PropTypes.number.isRequired,
  }).isRequired,
};

export default CountdownTimerCard;
