import React from "react";
import { Button, Row, Col } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

const UpdateEmployeeDetails = () => {
  const { modalStore } = useStore();
  const {
    setUpdateEmployeeDetailsModalVisible,
    setUpdatePersonalInfoModalVisible,
    setUpdatePhysicalInfoModalVisible,
    setUpdateSkillsInfoModalVisible,
    setUpdateEmploymentInfoModalVisible,
    setUpdateSalaryInfoModalVisible,
  } = modalStore;

  const handleOpenPersonalInfoForm = () => {
    setUpdateEmployeeDetailsModalVisible(false);
    setUpdatePersonalInfoModalVisible(true);
  };

  const handleOpenPhysicalInfoForm = () => {
    setUpdateEmployeeDetailsModalVisible(false);
    setUpdatePhysicalInfoModalVisible(true);
  };

  const handleOpenSkillsInfoForm = () => {
    setUpdateEmployeeDetailsModalVisible(false);
    setUpdateSkillsInfoModalVisible(true);
  };

  const handleOpenUpdateEmploymentInfoForm = () => {
    setUpdateEmployeeDetailsModalVisible(false);
    setUpdateEmploymentInfoModalVisible(true);
  };

  const handleOpenUpdateSalaryInfoForm = () => {
    setUpdateEmployeeDetailsModalVisible(false);
    setUpdateSalaryInfoModalVisible(true);
  };

  return (
    <>
      <h2 className="employee-modal-title">Edit Information</h2>

      <Row justify={"center"} style={{ marginTop: 35 }}>
        <Col>
          <Button
            className="update-details-btn"
            onClick={handleOpenPersonalInfoForm}
          >
            <h1 style={{ color: "black", fontSize: 30 }}>
              Personal <span style={{ color: "#FF7201" }}>Information</span>
            </h1>
          </Button>
        </Col>
        <Col style={{ marginTop: 35 }}>
          <Button
            className="update-details-btn"
            onClick={handleOpenPhysicalInfoForm}
          >
            <h1 style={{ color: "black", fontSize: 30 }}>
              Physical <span style={{ color: "#FF7201" }}>Information</span>
            </h1>
          </Button>
        </Col>
        <Col style={{ marginTop: 35 }}>
          <Button
            className="update-details-btn"
            onClick={handleOpenSkillsInfoForm}
          >
            <h1 style={{ color: "black", fontSize: 30 }}>
              Skills <span style={{ color: "#FF7201" }}>Information</span>
            </h1>
          </Button>
        </Col>
        <Col style={{ marginTop: 35 }}>
          <Button
            className="update-details-btn"
            onClick={handleOpenUpdateEmploymentInfoForm}
          >
            <h1 style={{ color: "black", fontSize: 30 }}>
              Employment <span style={{ color: "#FF7201" }}>Information</span>
            </h1>
          </Button>
        </Col>
        <Col style={{ marginTop: 35 }}>
          <Button
            className="update-details-btn"
            onClick={handleOpenUpdateSalaryInfoForm}
          >
            <h1 style={{ color: "black", fontSize: 30 }}>
              Salary <span style={{ color: "#FF7201" }}>Information</span>
            </h1>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default observer(UpdateEmployeeDetails);
