import { Table } from "antd";
import NotifyButton from "./buttons/NotifyButton";

const Documents = () => {
  const data = [
    {
      date: "2014-12-24 23:12:00",
      name: "Name 1",
      documentsToComply: "NBI CLEARANCE",
      status: "PENDING",
      action: "Notify",
      key: "1",
    },
    {
      date: "2014-12-24 23:12:00",
      name: "Name 2",
      documentsToComply: "NBI CLEARANCE",
      status: "PENDING",
      action: "Notify",
      key: "2",
    },
    {
      date: "2014-12-24 23:12:00",
      name: "Name 3",
      documentsToComply: "NBI CLEARANCE",
      status: "PENDING",
      action: "Notify",
      key: "3",
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "key",
    },
    {
      title: "Documents For Compliance",
      dataIndex: "documentsToComply",
      key: "key",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "key",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "key",
      render: () => <NotifyButton />,
      align: "center",
    },
  ];
  return (
    <div
      style={{
        width: "1240px",
        margin: "20px",
        padding: "0 30px 30px 30px",
        backgroundColor: "#EFEEEE",
        borderRadius: "20px",
        border: "4px solid #e6e6e6",
      }}
    >
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        title={() => <h2 style={{ textAlign: "center" }}>Documents</h2>}
      ></Table>
    </div>
  );
};

export default Documents;
