import React, { useState } from "react";
import { Table, ConfigProvider, Button } from "antd";
import { observer } from "mobx-react-lite";
import { useLeaveSummary } from "../../app/hooks/useLeaveSummary";

import ViewBtn from "./buttons/ViewBtn";

const AttendanceLeave = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading } = useLeaveSummary({
    pageNumber: pageNumber,
    pageSize: 10,
    sort: "date_descend",
  });

  const columns = [
    {
      title: "Employee Number",
      dataIndex: "employeeNumber",
      key: "employeeNumber",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Vacation Leave",
      dataIndex: "leaveTypes",
      key: "vacationLeave",
      align: "center",
      render: (leaveTypes) => {
        const leave = leaveTypes.find(
          (lt) => lt.leaveType === "Vacation Leave"
        );
        return (
          <p style={{ color: "black", fontSize: 17 }}>{leave?.count || 0}</p>
        );
      },
    },
    {
      title: "Personal Leave",
      dataIndex: "leaveTypes",
      key: "personalLeave",
      align: "center",
      render: (leaveTypes) => {
        const leave = leaveTypes.find(
          (lt) => lt.leaveType === "Personal Leave"
        );
        return (
          <p style={{ color: "black", fontSize: 17 }}>{leave?.count || 0}</p>
        );
      },
    },
    {
      title: "Emergency Leave",
      dataIndex: "leaveTypes",
      key: "emergencyLeave",
      align: "center",
      render: (leaveTypes) => {
        const leave = leaveTypes.find(
          (lt) => lt.leaveType === "Emergency Leave"
        );
        return (
          <p style={{ color: "black", fontSize: 17 }}>{leave?.count || 0}</p>
        );
      },
    },
    {
      title: "Sick Leave",
      dataIndex: "leaveTypes",
      key: "sickLeave",
      align: "center",
      render: (leaveTypes) => {
        const leave = leaveTypes.find((lt) => lt.leaveType === "Sick Leave");
        return (
          <p style={{ color: "black", fontSize: 17 }}>{leave?.count || 0}</p>
        );
      },
    },
    {
      title: "Offset",
      dataIndex: "leaveTypes",
      key: "offset",
      align: "center",
      render: (leaveTypes) => {
        const leave = leaveTypes.find((lt) => lt.leaveType === "Offset");
        return (
          <p style={{ color: "black", fontSize: 17 }}>{leave?.count || 0}</p>
        );
      },
    },
    {
      title: "Birthday Leave",
      dataIndex: "leaveTypes",
      key: "birthdayLeave",
      align: "center",
      render: (leaveTypes) => {
        const leave = leaveTypes.find(
          (lt) => lt.leaveType === "Birthday Leave"
        );
        return (
          <p style={{ color: "black", fontSize: 17 }}>{leave?.count || 0}</p>
        );
      },
    },
    {
      title: "Available Leave Credits",
      dataIndex: "availableCredits",
      key: "availableCredits",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Total Leave Count",
      dataIndex: "totalLeave",
      key: "totalLeave",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "History",
      key: "history",
      align: "center",
      render: (record) => <ViewBtn record={record} />,
    },
  ];

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "white",
          margin: 16,
          boxShadowSecondary:
            "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          borderRadius: 8,
          padding: 25,
        },
        components: {
          Table: {
            headerBg: "#FF7201",
            headerColor: "white",
            headerSplitColor: "#2C2C2C",
          },
        },
      }}
    >
      <Table
        rowKey="employeeId"
        columns={columns}
        dataSource={data}
        loading={isLoading}
        style={{ width: "1884px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Button
          type="primary"
          onClick={handlePreviousPage}
          style={{ marginRight: "10px" }}
        >
          Previous Page
        </Button>
        <Button type="primary" onClick={handleNextPage}>
          Next Page
        </Button>
      </div>
    </ConfigProvider>
  );
};

export default observer(AttendanceLeave);
