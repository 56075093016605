import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
//import { useRoles } from "../../../app/hooks/useRoles";
//import { toast } from "react-toastify";


const GetEmployeeNumberForm = () => {
  const [form] = Form.useForm();
  const { userManagementStore } = useStore();
  const { setGetEmployeeNumber, setIsModalVisible} = userManagementStore;
  //const {data} =useRoles();

  // const initialValues = {
  //   ...data,
  // }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleSubmit = async (values) => {
    try {
      await setGetEmployeeNumber(values.employeeNumber)
       
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        style={{ marginTop: 40 }}
        onFinish={handleSubmit}
      >
        <Col span={10}>
          <Form.Item
            label="Employee Number:"
            name="employeeNumber"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input/>
          </Form.Item>
        </Col>

        <Form.Item>
          <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(GetEmployeeNumberForm);
