import React, { useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import CoreEmployeeList from "../list/CoreEmployeeList";

const CoreDropdown = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button className="modalBtn" onClick={showModal}>
        <EllipsisOutlined className="dropdownIcon" />
      </button>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <h3 className="employee-modal-title">
          Core Employees
        </h3>
        <CoreEmployeeList/>
      </Modal>
    </>
  );
};

export default CoreDropdown;
