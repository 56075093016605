import { useState } from "react";
import { Steps } from "antd";

//observer
import { observer } from "mobx-react-lite";

//Employee Core Form
import PersonalInfoForm from "../CoreForm/PersonalInformationForm";
import PhysicalInfoForm from "../CoreForm/PhysicalInformationForm";
import SkillsForm from "../CoreForm/SkillsForm";
import EmploymentInfoForm from "../CoreForm/EmploymentInformationForm";
import SalaryInfoForm from "../CoreForm/SalaryInformationForm";
import ReviewinfoForm from "../CoreForm/ReviewInfoForm";
import PropTypes from "prop-types";

//Store
import { useStore } from "../../../app/stores/store";

const CoreForm = ({ onSubmit }) => {
  CoreForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const [formData] = useState({});
  const { employeeStore } = useStore();
  const {
    setPersonalInfo,
    personalInfoFormData,
    setPhysicalInfo,
    physicalInfoFormData,
    setSkillInfo,
    skillsInfoFormData,
    setEmploymentInfo,
    employmentInfoFormData,
    setSalaryInfo,
    salaryInfoFormData,
    setCoreFormCurrentStep,
    coreFormCurrentStep,
  } = employeeStore;

  // const handleNext = (values) => {
  //   setCurrentStep(currentStep + 1);
  //   setFormData({ ...formData, ...values });
  // };

  const handlePersonalInfo = (values) => {
    setCoreFormCurrentStep(coreFormCurrentStep + 1);
    setPersonalInfo(values);
  };

  const handlePhysicallInfo = (values) => {
    setCoreFormCurrentStep(coreFormCurrentStep + 1);
    setPhysicalInfo(values);
  };

  const handleSkillInfo = (values) => {
    setCoreFormCurrentStep(coreFormCurrentStep + 1);
    setSkillInfo(values);
  };

  const handleEmploymentInfo = (values) => {
    setCoreFormCurrentStep(coreFormCurrentStep + 1);
    setEmploymentInfo(values);
  };

  const handleSalaryInfo = (values) => {
    setCoreFormCurrentStep(coreFormCurrentStep + 1);
    setSalaryInfo(values);
  };

  const handlePrev = () => {
    setCoreFormCurrentStep(coreFormCurrentStep - 1);
  };

  const handleFinish = (values) => {
    // You can now submit the complete form data to your server or perform any other action.
    console.log("Form Data:", { ...formData, ...values });
    //handleCancel(); // Close the modal after finishing.
  };

  // Retrieve the previous data based on the current step
  const getPreviousData = (step) => {
    if (step === 1) {
      return personalInfoFormData; // Retrieve the personalInfoFormData from the store
    } else if (step === 2) {
      return physicalInfoFormData;
    } else if (step === 3) {
      return skillsInfoFormData;
    } else if (step === 4) {
      return employmentInfoFormData;
    } else if (step === 5) {
      return salaryInfoFormData;
    }
    // Add similar logic for other steps as needed
    return {};
  };

  return (
    <>
      <Steps current={coreFormCurrentStep}></Steps>
      {coreFormCurrentStep === 0 && (
        <PersonalInfoForm
          onNext={handlePersonalInfo}
          formData={getPreviousData(1)}
        />
      )}
      {coreFormCurrentStep === 1 && (
        <PhysicalInfoForm
          onNext={handlePhysicallInfo}
          onPrev={handlePrev}
          formData={getPreviousData(2)}
        />
      )}
      {coreFormCurrentStep === 2 && (
        <SkillsForm
          onNext={handleSkillInfo}
          onPrev={handlePrev}
          formData={getPreviousData(3)}
        />
      )}
      {coreFormCurrentStep === 3 && (
        <EmploymentInfoForm
          onNext={handleEmploymentInfo}
          onPrev={handlePrev}
          formData={getPreviousData(4)}
        />
      )}
      {coreFormCurrentStep === 4 && (
        <SalaryInfoForm
          onNext={handleSalaryInfo}
          onPrev={handlePrev}
          formData={getPreviousData(5)}
        />
      )}
      {coreFormCurrentStep === 5 && (
        <ReviewinfoForm
          onNext={handleFinish}
          onPrev={handlePrev}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default observer(CoreForm);
