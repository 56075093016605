import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { 
  Form, 
  Input, 
  Button, 
  Row, 
  Col, 
  DatePicker,
  Checkbox,
  //Upload,
  //message,
} from "antd";
//import dayjs from "dayjs";
//import { create }  from "../../../app/services/announcement/announcement";
import { useCurrentUser } from "../../../app/hooks/useCurrentUser";
import { useStore } from "../../../app/stores/store";
// import getBase64 from "../../../app/utils/getBase64";
import { useAnnouncement } from "../../../app/hooks/useAnnouncement";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const dateFormat = 'YYYY-MM-DD';

const AddEventForm = ({ onSubmit }) => {
  AddEventForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const { eventRequestStore } = useStore();
  const { setIsModalVisible, setAnnouncementId, setUpdatePhotoModalVisible } = eventRequestStore;
  const [form] = Form.useForm();
  const [ customType, setCustomType ] = useState(0);
  const { currentUser } = useCurrentUser();
  const {create} = useAnnouncement();
  //const [fileList, setFileList] = useState([]);


  const onChangePledge = e => { 
    if (e.target.checked) {
      setCustomType(1);
    } else if (!e.target.checked) {
      setCustomType(0);
    }
  };

  const onChangeCountdown = e => {
    if (e.target.checked) {
      setCustomType(2);
    } else if (!e.target.checked) {
      setCustomType(0);
    }
  };

  const handleSubmit = async (values) => {
    const updatedValues = {...values, 
      eventDate: values.eventDate.format(dateFormat), 
      customizeType: customType,
      createdByUserId: currentUser.user.id,
      status: 0,
    }

    try {
      await create(updatedValues).then((data)=>{
        setIsModalVisible(false);
        setUpdatePhotoModalVisible(true);
        onSubmit();
        setAnnouncementId(data?.id);
        toast.success("Success", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }).catch((error)=>{
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.title
        ) {
          // Display the specific title error in the toast
          toast.error(error.response.data.title, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          // Extract and display all error titles in toasts
          error.response.data.errors.forEach((errorItem) => {
            toast.error(errorItem.title, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        } else {
          // Display a generic error message
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    // const event = await create(updatedValues);
    // if (event) {
    //   message.success("Success!")
    //   setIsModalVisible(false);
    //   setUpdatePhotoModalVisible(true);
    //   onSubmit();
    //   setAnnouncementId(event?.id);
    // } else {
    //   message.error("Error on saving!")
    // }
  }

  // const handleChange = ({
  //   fileList: newFileList,
  //   }) => {
  //   console.log(fileList)
  //   try {
  //     if (newFileList) {
  //       setFileList(newFileList);
  //     }
  //   } catch (error) {
  //     console.error("Uploading error: ", error);
  //   }
  // };

  return (
    <>
      <Form form={form} layout="vertical" style={{ marginTop: 40 }} onFinish={handleSubmit}>
        <Row justify={"space-evenly"}>
          <Col span={16}>
            <Form.Item
              label="Announcement / Event Name:"
              name="name"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Date:"
              name="eventDate"
              rules={[{ required: true, message: "Required" }]}
              
            >
              <DatePicker format={dateFormat}/>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col span={21}>
            <Form.Item
              label="Description:"
              name="description"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-evenly"}>
          <Col span={6}>
            <p>Customize:</p>
            <Checkbox onChange={e => onChangePledge(e)}>Pledge</Checkbox>
            <Checkbox onChange={e => onChangeCountdown(e)}>Countdown Timer</Checkbox>
          </Col>
          {/* <Col span={10}>
            <div style={{ marginTop: 0, paddingTop: 0 }}>
              <h2><span style={{color: '#FF7201'}}>Upload</span> Photo</h2>
              <p>
                File Formats Accepted <span style={{fontWeight: 'bold'}}>PNG, JPG, JPEG</span> <br />
                Max. file size <span style={{fontWeight: 'bold'}}>20 MB</span> 
              </p>
              <Upload
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                onChange={handleChange}
              >
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#2C2C2C",
                    borderRadius: "83px",
                    width: "123.87px",
                  }}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </Col> */}
        </Row>
        <Form.Item style={{marginTop: '5%'}}>
          <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Submit
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
                onClick={()=>setIsModalVisible(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}

export default observer(AddEventForm);
