import React, { useState } from "react";
import { Button } from "antd";
import EmployeeDetailsModal from "../modal/EmployeeDetailsModal";
import PropTypes from "prop-types";

const EmployeeDetailsBtn = ({ id, onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  EmployeeDetailsBtn.propTypes = {
    id: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };
  return (
    <>
      <Button className="infoBtn" onClick={showModal}>
        VIEW INFO
      </Button>
      {isModalOpen && (
        <EmployeeDetailsModal id={id} handleCancel={handleCancel} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default EmployeeDetailsBtn;
