import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import PropTypes from "prop-types";

const SkillsForm = ({ onNext, onPrev, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);

  SkillsForm.propTypes = {
    onNext : PropTypes.func.isRequired,
    onPrev : PropTypes.func.isRequired,
    formData : PropTypes.object.isRequired,
  }
  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          II. <span style={{ color: "#FF7201" }}>Skills</span>
        </h2>
        <Row justify={"space-between"}>
          <Col span={24}>
            <Form.Item
              label="Computer Literate/Proficiency:"
              name="computerProficiency"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} justify={"space-between"}>
          <Col span={12}>
            <Row>
              <h2 className="core-form-title">
                III. <span style={{ color: "#FF7201" }}>Comprehension </span>
                Skills
              </h2>
              <Col span={24}>
                <Form.Item
                  label="Narration (Based on Conducted Test):"
                  name="narration"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Gives correct summarization" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Understanding (Based on Conducted Test):"
                  name="understanding"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Determine moral lesson" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Connection (Based on Conducted Test):"
                  name="connection"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Determine moral lesson" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <h2 className="core-form-title">
                IV. <span style={{ color: "#FF7201" }}>Communication</span>
              </h2>
              <Col span={24}>
                <Form.Item
                  label="Pronunciation:"
                  name="pronunciation"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Diction:"
                  name="diction"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Personality Remarks: (Level of Confident/Persuasiveness/Creativity)"
                  name="personalityRemarks"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify={"end"}>
          <Col span={12}>
            <Form.Item
              label="Enthusiasm"
              name="enthusiasm"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={onPrev}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default SkillsForm;
