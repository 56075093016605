import { makeAutoObservable, runInAction } from "mobx";
import { getEventsById } from "../services/events/getEvents";

export default class EventsStore {
  eventData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setGetEventsId = async (values) => {
    runInAction(async () => {
      const response = await getEventsById(values);
      this.eventData = { ...this.eventData, ...response };
    });
  };
}
