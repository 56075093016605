import React from "react";
import { Row, Col, Button } from "antd";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import UploadAnnouncementImage from "../../common/UploadAnnouncementImage";
import UploadAnnouncementReviewImage from "../../common/UploadAnnouncementReviewImage";
import { toast } from "react-toastify";
//import PropTypes from "prop-types";
import { useAnnouncement } from "../../../app/hooks/useAnnouncement";

const UploadAnnouncementPhotoModal = () => {
  const { eventRequestStore } = useStore();
  const { image, uploadPhotoObject, setUpdatePhotoModalVisible } =
    eventRequestStore;
  const { patch } = useAnnouncement();

  const handleSave = async () => {
    // Check if the image is not null before patching the employee
    // Check if the image is not null before patching the employee
    if (image) {
      // Use the patch function to update the employee with the new photo

      await patch(uploadPhotoObject);

      setUpdatePhotoModalVisible(false);
      // Close the modal after saving
      //hideModal();
      //onSubmit();

      toast.success("Successfully Added Photo", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // Display a toast message when no image is selected
      toast.error("Please Select an image", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <Row justify={"center"}>
        <Col style={{ marginTop: 20 }}>
          <h2 className="core-form-title">
            <span style={{ color: "#FF7201" }}>Upload </span>
            Profile Photo
          </h2>
          <p className="upload-img-txt">
            File Formats Accepted
            <span className="upload-img-span">PNG, JPG, JPEG</span>
          </p>
          <p className="upload-img-txt">
            Max. file size <span className="upload-img-span">20 MB</span>
          </p>
          <Row justify={"center"}>
            <Col>
              <UploadAnnouncementImage />
            </Col>
          </Row>
          <Row justify={"center"}  style={{ marginTop: 20 }}>
            <Col>
              <UploadAnnouncementReviewImage />
            </Col>
          </Row>
          <Row justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button onClick={handleSave}>Save</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default observer(UploadAnnouncementPhotoModal);
