import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Row, Col } from "antd";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Total Attendance Report",
      color: "white",
      font: {
        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        size: 23,
      },
    },
  },
  scales: {
    x: {
      title: {
        color: "white", // X-axis label color
      },
      ticks: {
        color: "white", // X-axis ticks color
      },
    },
    y: {
      title: {
        color: "white", // Y-axis label color
      },
      ticks: {
        color: "white", // Y-axis ticks color
      },
    },
  },
  elements: {
    point: {
      backgroundColor: "white", // Data point color
      borderColor: "red", // Data point border color
      borderWidth: 1, // Data point border width
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July", "August"];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [80, 120, 160, 140, 200, 180, 250, 10],
      borderColor: "white",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const TotalAttendanceReport = () => {
  return (
    <Row>
      <Col span={24}>
        <div className="total-attendance-report-container">
          <Line
            options={options}
            data={data}
            style={{
              marginBottom: 10,
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default TotalAttendanceReport;
