import React, { useState } from "react";
import { Table, ConfigProvider, Modal, Select, Button } from "antd";
import PropTypes from "prop-types";
import { useLeaveApplication } from "../../app/hooks/useLeaveApplications";
import moment from "moment";
import { toast } from "react-toastify";

const { Option } = Select;

const HistoryList = ({ record }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading, update, mutate } = useLeaveApplication({
    pageNumber: pageNumber,
    pageSize: 10,
    employeeId: record.employeeId,
    sort: "date_descend",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);

  HistoryList.propTypes = {
    record: PropTypes.any.isRequired,
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showStatusModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const updateLeaveStatusObj = {
        leaveApplicationId: currentRecord?.id,
        status: selectedStatus,
      };
      await update(updateLeaveStatusObj)
        .then(() => {
          mutate();
          toast.success("Successfully Update Status", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsModalVisible(false);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const columns = [
    {
      title: "Request Date of Leave",
      dataIndex: ["startDate", "endDate"],
      key: "requestDate",
      align: "center",
      render: (text, record) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {moment(record.startDate).format("MMMM DD, YYYY")} -{" "}
          {moment(record.endDate).format("MMMM DD, YYYY")}
        </p>
      ),
    },
    {
      title: "Total No. of Days",
      dataIndex: "totalCredit",
      key: "totalCredit",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Purpose/Reason",
      dataIndex: "reason",
      key: "reason",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Leave Type",
      dataIndex: "leaveTypeName",
      key: "leaveTypeName",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "Date Filled",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {moment(text).format("MMMM DD, YYYY")}
        </p>
      ),
      align: "center",
    },
    {
      title: "Status",
      key: "statusDesc",
      dataIndex: "statusDesc",
      render: (text) => {
        let color;
        switch (text) {
          case "ForApproval":
            color = "#CFC700";
            break;
          case "Approved":
            color = "#008C27";
            break;
          case "Rejected":
            color = "#FF0000";
            break;
          default:
            color = "black";
        }
        return <p style={{ color, fontSize: 17 }}>{text}</p>;
      },
      align: "center",
    },
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            padding: 10,
          },
          components: {
            Table: {
              // Seed Token
              headerBg: "#FF7201",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data}
          loading={isLoading}
          style={{ width: "100%" }}
          onRow={(record) => ({
            onClick: () => showStatusModal(record),
          })}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            type="primary"
            onClick={handlePreviousPage}
            style={{ marginRight: "10px" }}
          >
            Previous Page
          </Button>
          <Button type="primary" onClick={handleNextPage}>
            Next Page
          </Button>
        </div>
      </ConfigProvider>

      <Modal
        title="Update Status"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Select a status"
          onChange={(value) => setSelectedStatus(value)}
        >
          <Option value={0}>For Approval</Option>
          <Option value={1}>Approved</Option>
          <Option value={2}>Rejected</Option>
        </Select>
      </Modal>
    </>
  );
};

export default HistoryList;
