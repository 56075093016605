import React, { useState } from "react";
import { Space, Table, ConfigProvider, Button, Row, Col } from "antd";
import { observer } from "mobx-react-lite";
import { useAnnouncement } from "../../app/hooks/useAnnouncement";
import moment from "moment";
import { Link } from "react-router-dom";

const EventRequestList = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data } = useAnnouncement({
    pageNumber: pageNumber,
    pageSize: 10,
    sort: "date_descend",
  });
  const columns = [
    {
      title: "JO #",
      dataIndex: "jo",
      key: "jo",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "EVENT NAME",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
    },
    {
      title: "TOTAL MANPOWER",
      key: "totalMan",
      dataIndex: "totalMan",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
      align: "center",
    },
    {
      title: "PM",
      key: "pm",
      dataIndex: "pm",
      render: (text) => <p style={{ color: "black", fontSize: 17 }}>{text}</p>,
      align: "center",
    },
    {
      title: "DATE REQUESTED",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text) => (
        <p style={{ color: "black", fontSize: 17 }}>
          {moment(text).format("MMMM DD, YYYY")}
        </p>
      ),
      align: "center",
    },
    {
      title: "",
      key: "id",
      dataIndex: "id",
      render: (id) => (
        <Link to={`/events/${id}`}>
          <Space size="middle">
            <Button
              style={{ backgroundColor: "#FF7201", color: "white" }}
              //onClick={() => handleEdit(record)}
            >
              VIEW
            </Button>
          </Space>
        </Link>
      ),
      align: "center",
    },
  ];

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            margin: 16,
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 8,
            padding: 25,
          },
          components: {
            Table: {
              // Seed Token
              headerBg: "#2C2C2C",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
          <Row justify="center">
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={data}
              //loading={isLoading}
              style={{ width: "1284px" }}
            />
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Button
              type="primary"
              onClick={handlePreviousPage}
              style={{ marginRight: "10px" }}
            >
              Previous Page
            </Button>
            <Button type="primary" onClick={handleNextPage}>
              Next Page
            </Button>
          </Col>
        </Row>
      </ConfigProvider>
    </>
  );
};

export default observer(EventRequestList);
