import React, { useState, useEffect } from "react";
import moment from "moment";

function TimeCard() {
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss A"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss A"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const currentDate = moment().format("dddd, MMMM D, YYYY");

  return (
    <div className="p-1">
      <div className="shadow-lg rounded-lg overflow-hidden max-w-sm px-5" style={{ backgroundColor: 'white' }}>
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-2">{currentTime}</h1>
          <div className="flex justify-between mb-4">
            <span style={{ color: "#FF7201" }}>{currentDate}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeCard;
