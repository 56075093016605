import useSWR from "swr";

import { api } from "../services";

const BASE_URL = "/system-admin/positions";

export const usePositions = (query = {}) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);
  const create = async (data) => {
    const positions = await api.post(BASE_URL, data);
    return positions;
  };
  const update = async (data) => {
    const positions = await api.put(BASE_URL, data);
    return positions;
  };
  const find = async (query) => {
    const positions = await api.get(`${BASE_URL}/find?search=${query}`);
    return positions;
  };
  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    update,
    find,
    mutate,
  };
};

export const usePositionsById = (id) => {
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}/${id}`, api.get);

  const remove = async (id) => {
    const positions = await api.delete(`${BASE_URL}/${id}`);
    return positions;
  };
  return {
    isLoading,
    data,
    mutate,
    remove,
  };
};
