import useSWR from "swr";
import { api } from "../services";

const BASE_URL = "/system-admin/attendance-records";


export const useAttendance = ( query = {} ) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);

  return {
    isLoading,
    data: data,
    count: data?.length,
    mutate,
  };
};