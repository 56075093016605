import useSWR from "swr";

import { api } from "../services";

const EMPLOYEE_COUNT_URL = "/web-user/employees/stats";
const DASHBOARD_EMPLOYEE_COUNT_URL = "/web-user/dashboard/stats";


export const useEmployeeCount = () => {
  const { data, isLoading, mutate } = useSWR(EMPLOYEE_COUNT_URL, api.get);

  return {
    isLoading,
    currentCount: data,
    data,
    mutate,
  };
}

export const useEmployeeDashboardCount = () => {
  const { data, isLoading, mutate } = useSWR(DASHBOARD_EMPLOYEE_COUNT_URL, api.get);

  return {
    isLoading,
    currentCount: data,
    data,
    mutate,
  };
}

