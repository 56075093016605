import { Empty } from "antd";
import Pic from "../../../app/assets/images/balloons.png";
import AnnivViewAllBtn from "./buttons/AnnivViewAllBtn";
import { useCurrentAnniversary } from "../../../app/hooks/useCurrentAnniversary";

export const AnniversaryRecord = [
  {
    id: 0,
    name: "Pedro Penduko MG",
    photo: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=2",
    dateEmployed: "September 13, 2023",
  },
  {
    id: 1,
    name: "Mike Pacquaioasdf",
    photo: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=1",
    dateEmployed: "January 7, 2023",
  },
  {
    id: 2,
    name: "Mike Pacquaioasdf",
    photo: "https://xsgames.co/randomusers/avatar.php?g=pixel&key=1",
    dateEmployed: "August 24, 2023",
  },
];

const EmployeeAnniversary = () => {
  const { currentDate } = useCurrentAnniversary();
  return (
    <div style={{ display: "flex" }} className="right-side-card-container">
      {/* temporary icon */}
      <img
        src={Pic}
        alt="Your Logo"
        className="icon2"
        style={{ margin: 30, width: 50, height: 50 }}
      />
      <div style={{ textAlign: "center", width: "250px" }}>
        <h3>Employment Anniversary</h3>
        {currentDate === undefined || currentDate.length == 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <div>
            <span>
              {currentDate.map((anniversary, index) => {
                return (
                  <span key={anniversary.id}>
                    {(index
                      ? anniversary.id === currentDate.length - 1
                        ? " and "
                        : ", "
                      : "") +
                      anniversary.firstName +
                      " " +
                      anniversary.lastName +
                      " "}
                  </span>
                );
              })}
              <span>
                {" "}
                {currentDate.length == 1 ? "has a" : "have"}{" "}
                {currentDate.length == 1 ? "anniversary" : "anniversaries"}{" "}
                today.
              </span>
            </span>
            <AnnivViewAllBtn />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeAnniversary;
