import React from 'react';
import { Spin } from 'antd';

const LoadingScreen = () => {
  return(
    <>
      <Spin tip="Loading..." size="large"/>
    </>
  )
}

export default LoadingScreen;