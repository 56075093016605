import { Table } from "antd";

const RecentNotifications = () => {
  const data = [
    {
      date: "2014-12-24 23:12:00",
      sender: "Sender 1",
      request: "Request",
      status: "UNREAD",
      key: "1",
    },
    {
      date: "2014-12-24 23:12:00",
      sender: "Sender 2",
      request: "Request",
      status: "UNREAD",
      key: "2",
    },
    {
      date: "2014-12-24 23:12:00",
      sender: "Sender 3",
      request: "Request",
      status: "UNREAD",
      key: "3",
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "key",
    },
    {
      title: "Sender",
      dataIndex: "sender",
      key: "key",
    },
    {
      title: "Request",
      dataIndex: "request",
      key: "key",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "key",
    },
  ];

  return (
    <div
      style={{
        width: "1240px",
        margin: "20px",
        padding: "0 30px 30px 30px",
        backgroundColor: "#EFEEEE",
        borderRadius: "20px",
        border: "4px solid #e6e6e6",
      }}
    >
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        title={() => (
          <h2 style={{ textAlign: "center" }}>Recent Notifications</h2>
        )}
      ></Table>
    </div>
  );
};

export default RecentNotifications;
