import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import PrivateLayout from "./app/layout/PrivateLayout";

// Private Route
import Dashboard from "./pages/Dashboard/Dashboard";
import Employees from "./pages/Employees/Employees";
import UserManagement from "./pages/UserManagement/UserManagement";
import EventRequest from "./pages/EventRequest/EventRequest";
import Events from "./pages/Events/Events";
import Attendance from "./pages/Attendance/Attendance";
import Leave from "./pages/Leave/Leave";

// Public Route
import Login from "./pages/Login/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import NotFound from "./pages/NotFound/NotFound";

import { useCurrentUser } from "./app/hooks/useCurrentUser";

const PrivateWrapper = () => {
  const { isLoggedIn, error } = useCurrentUser();
  if (
    localStorage.getItem("jwt_token") == null ||
    isLoggedIn == false ||
    error === "Unauthorized" ||
    localStorage.getItem("jwt_token") === 'undefined'
  )
    return <Navigate to="/login" />;
  return (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  );
};

function App() {
  // Use the useCurrentUser hook to get user information

  // If user data is still loading, you can show a loading spinner or another loading indicator
  const { isLoading, token } = useCurrentUser();

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem("jwt_token", token);
    }
  }, [isLoading, token]);

  return (
    <Router>
      <Routes>
        <Route element={<PrivateWrapper />}>
          <Route index element={<Dashboard />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/event-request" element={<EventRequest />} />
          <Route path="/events/:id" element={<Events />} />
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/leave" element={<Leave />} />
        </Route>

        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
