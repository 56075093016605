import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

const SalaryInfoForm = ({ onNext, onPrev, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);

  SalaryInfoForm.propTypes = {
    onNext : PropTypes.func.isRequired,
    onPrev : PropTypes.func.isRequired,
    formData : PropTypes.object.isRequired,
  }
  const [inflationFields, setInflationFields] = useState([
    { inflation: null, rate: null },
  ]);

  const handleAddInflationField = () => {
    const newInflationFields = [
      ...inflationFields,
      { inflation: null, rate: null },
    ];
    setInflationFields(newInflationFields);
  };

  const handleRemoveInflationField = (index) => {
    const newInflationFields = [...inflationFields];
    newInflationFields.splice(index, 1);
    setInflationFields(newInflationFields);
  };

  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          III. <span style={{ color: "#FF7201" }}>Salary </span>Information
        </h2>

        <h5>Income</h5>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              name="basicPay"
              label="Basic Pay:"
              rules={[
                {
                  required: true,
                  message: "Require",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Allowances:"
              name="allowances"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Deminimis" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="fringeBenefits"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Fringe Benefits" style={{ marginTop: 30 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Vatable Amount:"
              name="vatableAmount"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="number" placeholder="0" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Net of Tax:"
              name="netOfTax"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"} gutter={20}>
          <Col span={7}>
            <Form.Item
              label="Provisional Pay:"
              name={`inflationFields[inflation]`}
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear placeholder="Inflation">
                <Option value="inflation">Inflation</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name={`inflationFields[rate]`}
              rules={[{ required: true, message: "Required" }]}
            >
              <Input
                type="number"
                placeholder="Rate"
                style={{ marginTop: 30 }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#F47F20",
                borderRadius: "83px",
                width: "94px",
                marginTop: 30 
              }}
              onClick={handleAddInflationField}
            >
              Add
            </Button>
          </Col>
        </Row>
        {inflationFields.slice(1).map((field, index) => (
          <Row key={index} justify={"space-between"} gutter={20}>
            <Col span={7}>
              <Form.Item
                label="Provisional Pay:"
                name={`inflation[${index + 1}]`}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select allowClear placeholder="Inflation">
                  <Option value="inflation">Inflation</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name={`rate[${index + 1}]`}
                rules={[{ required: true, message: "Required" }]}
              >
                <Input
                  type="number"
                  placeholder="Rate"
                  style={{ marginTop: 30 }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Button
                //type="danger"
                style={{
                  marginTop: 30,
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={() => handleRemoveInflationField(index + 1)}
              >
                X
              </Button>
            </Col>
          </Row>
        ))}

        <h5>Deductions (Company)</h5>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              name="hmo"
              label="HMO DEPENDENTS:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="loans"
              label="Loans:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              name="carLoan"
              label="Car Loan:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              name="cashAdvance"
              label="Cash Advance:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="suspension"
              label="Suspension:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              name="accountability"
              label="Accountability:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h5>Government Mandates</h5>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              name="sssRemittance"
              label="SSS Remittance:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="pagIbigRemittance"
              label="PAGIBIG Remittance"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              name="philHealthRemittance"
              label="Philhealth Remittance"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <h5>Government Loans</h5>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="sss"
              label="SSS:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="pagIbig"
              label="PAGIBIG:"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={onPrev}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default SalaryInfoForm;
