import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEmployee } from "../../../app/hooks/useEmployee";
import { toast } from "react-toastify";

const UpdateSkillsInfoForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  UpdateSkillsInfoForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  const { update } = useEmployee();
  const { updateEmployeeStore, modalStore } = useStore();
  const { personalInfoFormData, setPersonalInfo } = updateEmployeeStore;
  const {
    setUpdateEmployeeDetailsModalVisible,
    setUpdateSkillsInfoModalVisible,
  } = modalStore;

  const handleBack = () => {
    setUpdateEmployeeDetailsModalVisible(true);
    setUpdateSkillsInfoModalVisible(false);
  };

  const initialValues = {
    ...personalInfoFormData,
    computerProficiency: personalInfoFormData?.employeeSkill?.computerLiteracy,
    narration: personalInfoFormData?.employeeComprehensionSkill?.narration,
    understanding:
      personalInfoFormData?.employeeComprehensionSkill?.understanding,
    connection: personalInfoFormData?.employeeComprehensionSkill?.connection,
    pronunciation:
      personalInfoFormData?.employeeCommunicationSkill?.pronounciation,
    diction: personalInfoFormData?.employeeCommunicationSkill?.diction,
    enthusiasm: personalInfoFormData?.employeeCommunicationSkill?.enthusiam,
    personalityRemarks:
      personalInfoFormData?.employeeCommunicationSkill?.personalityRemarks,
  };

  const handleSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();

      const updateFormData = {
        ...personalInfoFormData,
        employeeSkill: {
          employeeId : personalInfoFormData?.id,
          computerLiteracy:
            formValues.computerProficiency ||
            personalInfoFormData?.employeeSkill?.computerLiteracy,
        },
        employeeComprehensionSkill: {
          employeeId : personalInfoFormData?.id,
          narration:
            formValues.narration ||
            personalInfoFormData?.employeeComprehensionSkill?.narration,
          understanding:
            formValues.understanding ||
            personalInfoFormData?.employeeComprehensionSkill?.understanding,
          connection:
            formValues.connection ||
            personalInfoFormData?.employeeComprehensionSkill?.connection,
        },
        employeeCommunicationSkill: {
          employeeId : personalInfoFormData?.id,
          pronounciation:
            formValues.pronunciation ||
            personalInfoFormData?.employeeCommunicationSkill?.pronounciation,
          diction:
            formValues.diction ||
            personalInfoFormData?.employeeCommunicationSkill?.diction,
          enthusiam:
            formValues.enthusiasm ||
            personalInfoFormData?.employeeCommunicationSkill?.enthusiam,
          personalityRemarks:
            formValues.personalityRemarks ||
            personalInfoFormData?.employeeCommunicationSkill
              ?.personalityRemarks,
        },
      };

      setPersonalInfo(updateFormData);

      await update(updateFormData)
        .then(() => {
          setUpdateEmployeeDetailsModalVisible(true);
          setUpdateSkillsInfoModalVisible(false);
          onSubmit();
          toast.success("Successfully Update Skills Information", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        <h2 className="core-form-title">
          II. <span style={{ color: "#FF7201" }}>Skills</span>
        </h2>
        <Row justify={"space-between"}>
          <Col span={24}>
            <Form.Item
              label="Computer Literate/Proficiency:"
              name="computerProficiency"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} justify={"space-between"}>
          <Col span={12}>
            <Row>
              <h2 className="core-form-title">
                III. <span style={{ color: "#FF7201" }}>Comprehension </span>
                Skills
              </h2>
              <Col span={24}>
                <Form.Item
                  label="Narration (Based on Conducted Test):"
                  name="narration"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Gives correct summarization" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Understanding (Based on Conducted Test):"
                  name="understanding"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Determine moral lesson" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Connection (Based on Conducted Test):"
                  name="connection"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input placeholder="Determine moral lesson" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <h2 className="core-form-title">
                IV. <span style={{ color: "#FF7201" }}>Communication</span>
              </h2>
              <Col span={24}>
                <Form.Item
                  label="Pronunciation:"
                  name="pronunciation"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Diction:"
                  name="diction"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Personality Remarks: (Level of Confident/Persuasiveness/Creativity)"
                  name="personalityRemarks"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify={"end"}>
          <Col span={12}>
            <Form.Item
              label="Enthusiasm"
              name="enthusiasm"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Update
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={handleBack}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(UpdateSkillsInfoForm);
