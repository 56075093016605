import useSWR from "swr";
import { api } from "../services";

const BASE_URL = "account";

export const useCurrentUser = () => {
  const { data, isLoading, mutate} = useSWR(`${BASE_URL}/me`, async (url) => {
    try {
      const res = await api.get(url);
      return { user: res, isLoggedIn: true };
    } catch (error) {
      if (error.response && error.response.data.status === 401) {
        return { user: null, isLoggedIn: false, error: "Unauthorized"};
        // return localStorage.clear();
      }
      throw error;
    }
  });

  return {
    isLoading,
    currentUser: data,
    instance: data?.user?.instance,
    role: data?.user?.roles,
    mutate,
    isLoggedIn: data?.isLoggedIn,
    error: data?.error,
    token: data?.user?.accessToken,
  };
};