import React from "react";
import { Table, ConfigProvider } from "antd";
import PropTypes from "prop-types";

const ModalTable = ({ data }) => {
  ModalTable.propTypes = {
    data: PropTypes.array.isRequired,
  };

  const columns = [
    {
      title: "Emp#",
      dataIndex: "results",
      key: "results",
      align: "center",
      render: (results) => {
        if (results?.employmentInfo && results?.employmentInfo.employeeNum) {
          return `${results?.employmentInfo.employeeNum}`;
        } else {
          return "N/A"; // or some default value if personalInfo is not defined
        }
      },
    },
    {
      title: "Employee Name",
      dataIndex: "results",
      key: "results",
      align: "center",
      render: (results) => {
        if (
          results?.personalInfo &&
          results?.personalInfo.firstName &&
          results?.personalInfo.lastName
        ) {
          return `${results?.personalInfo.firstName} ${results?.personalInfo.middleName} ${results?.personalInfo.lastName}`;
        } else {
          return "N/A"; // or some default value if personalInfo is not defined
        }
      },
    },
    {
      title: "Position",
      key: "results",
      dataIndex: "results",
      align: "center",
      render: (results) => {
        if (results?.employmentInfo && results?.employmentInfo.position) {
          return `${results?.employmentInfo.position}`;
        } else {
          return "N/A"; // or some default value if personalInfo is not defined
        }
      },
    },
    {
      title: "Department",
      key: "results",
      dataIndex: "results",
      align: "center",
      render: (results) => {
        if (results?.employmentInfo && results?.employmentInfo.department) {
          return `${results?.employmentInfo.department}`;
        } else {
          return "Board"; // or some default value if personalInfo is not defined
        }
      },
    },
    {
      title: "Date",
      key: "results",
      dataIndex: "results",
      align: "center",
      render: (results) => {
        if (results?.employmentInfo && results?.employmentInfo.dateHired) {
          return `${results?.employmentInfo.dateHired}`;
        } else {
          return "N/A"; // or some default value if personalInfo is not defined
        }
      },
    },
  ];
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "white",
            margin: 16,
            boxShadowSecondary:
              "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: 8,
            padding: 25,
          },
          components: {
            Table: {
              // Seed Token
              headerBg: "#FF7201",
              headerColor: "white",
              headerSplitColor: "#2C2C2C",
            },
          },
        }}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data}
          //loading={isLoading}
          style={{ width: "1284px" }}
        />
      </ConfigProvider>
    </>
  );
};

export default ModalTable;
