import {
  Button, Modal, TimePicker, Row, Col, Divider, Form
} from "antd";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import moment from 'moment';
import { useStore } from "../../../../app/stores/store";
import { useShiftCode } from "../../../../app/hooks/useShiftCode";

const TimePickerBtn = () => {
  const {count} = useShiftCode({
    pageNumber: 0,
    pageSize:9999
  });
  const { timeStore } = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeIn, setTimeIn] = useState(moment('12:00 PM', 'hh:mm A')); // Declare timeIn
  const [timeOut, setTimeOut] = useState(null); // Declare timeOut
  const [isTimeIn, setIsTimeIn] = useState(true); // New state for managing "time in" or "time out"

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsTimeIn(true); // Reset the state when modal is closed
  };

  const handleTimeChange = (time) => {
    if (isTimeIn) {
      setTimeIn(time); // Update timeIn
    } else {
      setTimeOut(time); // Update timeOut
    }
  };

  const handleNextClick = () => {
    const formattedTimeIn = timeIn ? timeIn.format('hh:mm A') : null;
    timeStore.setSelectedTimeIn(formattedTimeIn); // Save the "Time In" to the store
    setIsTimeIn(false);
    setTimeOut(null);
    timeStore.setDefaultCode(count);
  };

  const handleSaveClick = () => {
    const formattedTimeOut = timeOut ? timeOut.format('hh:mm A') : null;
    timeStore.setSelectedTimeOut(formattedTimeOut); // Save the "Time Out" to the store
    setIsModalOpen(false);
    setIsTimeIn(true);
  };

  return (
    <>
      <Button
        onClick={showModal}
        style={{
          backgroundColor: "transparent",
          borderColor: "transparent",
          color: "#ff7201",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "none",
          fontSize: "30px"
        }}
      >
        +
      </Button>

      <Modal
        footer={null}
        closable={false}
        style={{ top: 300 }}
        width={300}
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col>
            <h3>
              SET <span style={{ color: "#FF7201" }}>TIME</span>
            </h3>
          </Col>
          <Divider />
          <Col style={{ marginBottom: '50px' }}>
            <h2>{isTimeIn ? 'Time In' : 'Time Out'}</h2> {/* Display "Time In" or "Time Out" based on isTimeIn state */}
            <TimePicker
              use12Hours
              format="hh:mm A"
              onChange={handleTimeChange}
              style={{ height: '60px', width: '200px', borderLeft: 'none', borderRight: 'none', borderColor: 'black', borderRadius: 0 }}
            />
          </Col>
          <Form.Item>
            <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
              {isTimeIn ? (
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleNextClick} // Use handleNextClick for "Next" button
                    style={{
                      backgroundColor: "#F47F20",
                      borderRadius: "83px",
                      width: "100px",
                    }}
                  >
                    Next
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSaveClick} // Use handleSaveClick for "Save" button
                    style={{
                      backgroundColor: "#F47F20",
                      borderRadius: "83px",
                      width: "100px",
                    }}
                  >
                    Save
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type="default"
                  onClick={handleCancel}
                  style={{
                    backgroundColor: "#2C2C2C",
                    borderRadius: "83px",
                    width: "100px",
                    color: "#ffff",
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Row>
      </Modal >
    </>
  );
};

export default observer(TimePickerBtn);
