import { makeAutoObservable, runInAction } from "mobx";


export default class ModalStore {
  subCoreModalVisible = false;
  subManModalVisible = false;

  updateStatusModal = false;

  uploadPhotoModal = false;

  updateEmployeeDetails = false;

  updatePersonalInfoModal = false;

  updatePhysicalInfoModal = false;

  updateSkillsInfoModal = false;

  updateEmploymentInforModal = false;

  updateSalaryInforModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSubCoreModalVisible = (bool) => {
    runInAction(()=>{
      this.subCoreModalVisible = bool;
    })
  }

  setSubMaModalVisible = (bool) => {
    runInAction(()=>{
      this.subManModalVisible = bool;
    })
  }

  setUpdateStatusModalVisible = (bool) => {
    runInAction(()=>{
      this.updateStatusModal = bool;
    })
  }

  setUpdatePhotoModalVisible = (bool) => {
    runInAction(()=>{
      this.uploadPhotoModal = bool;
    })
  }

  setUpdateEmployeeDetailsModalVisible = (bool) => {
    runInAction(()=>{
      this.updateEmployeeDetails = bool;
    })
  }

  setUpdatePersonalInfoModalVisible = (bool) => {
    runInAction(()=>{
      this.updatePersonalInfoModal = bool;
    })
  }

  setUpdatePhysicalInfoModalVisible = (bool) => {
    runInAction(()=>{
      this.updatePhysicalInfoModal = bool;
    })
  }

  setUpdateSkillsInfoModalVisible = (bool) => {
    runInAction(()=>{
      this.updateSkillsInfoModal = bool;
    })
  }

  setUpdateEmploymentInfoModalVisible = (bool) => {
    runInAction(()=>{
      this.updateEmploymentInforModal = bool;
    })
  }

  setUpdateSalaryInfoModalVisible = (bool) => {
    runInAction(()=>{
      this.updateSalaryInforModal = bool;
    })
  }

}