import React from "react";
//import { Modal } from "antd";
//import EmployeeStatus from "../status/EmployeeStatus";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import PropTypes from "prop-types";


const EmployeeUpdateStatusBtn = ({ id,status }) => {
  const {modalStore, updateEmployeeStore} = useStore();
  const {setUpdateStatusModalVisible} = modalStore;
  const {setGetEmployeeId} = updateEmployeeStore;
  let color = "";
  if (status === "Regular") {
    color = "#0000FF";
  } else if (status === "ProjectBased" || status === "JobOrder") {
    color = "#FF7201"; // Change this to the desired color for another status
  } else if (status === "Separated") {
    color = "#FF0000"; // Set a default color if needed
  }

  const showModal = () => {
    setUpdateStatusModalVisible(true);
    setGetEmployeeId(id);
  };
  // const handleOk = () => {
  //   setUpdateStatusModalVisible(false);
  // };
  // const handleCancel = () => {
  //   setUpdateStatusModalVisible(false);
  // };

  EmployeeUpdateStatusBtn.propTypes = {
    id: PropTypes.number.isRequired,
    status : PropTypes.string.isRequired,
  }
  return (
    <>
      <button
        className="updateStatusBtn"
        style={{ backgroundColor: color }}
        onClick={showModal}
      ></button>
      {/* <Modal
        title={
          <h2 style={{ textAlign: "center" }}>Update Employment Status</h2>
        }
        footer={null}
        style={{ top: 100 }}
        width={900}
        className="modalContainer"
        open={updateStatusModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <EmployeeStatus id={id}/>
        </div>
      </Modal> */}
    </>
  );
};

export default observer(EmployeeUpdateStatusBtn);
