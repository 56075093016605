import React, { useState } from 'react'
import { Button, Modal, Row, Col, Divider } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import HistoryList from "../HistoryList";
import PropTypes from "prop-types";

const ViewBtn = ({record}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

 ViewBtn.propTypes = {
    record: PropTypes.any.isRequired,
  };


  return (
    <>
      <Button
        onClick={showModal}
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
      >
        VIEW
      </Button>

      <Modal
        footer={null}
        closable={false}
        style={{ top: 200 }}
        width={1000}
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Row justify={"space-between"}>
          <Col>
            <h3 style={{ color: "#FF7201" }}>
              Leave <span style={{ color: "#000000" }}>History</span>
            </h3>
          </Col>
          <Button
            style={{
              background: "#FF7201",
              color: "#FFFFFF",
              borderRadius: "50px",
              marginTop: 15,
            }}
          >
            <ImportOutlined />
            EXPORT
          </Button>
        </Row>
        <Divider />
        <HistoryList record={record}/>
      </Modal>
    </>

  )
}

export default ViewBtn
