import React from "react";
import { Steps } from "antd";

//observer
import { observer } from "mobx-react-lite";

//Common Forms
import PersonalInformationForm from "../CoreForm/PersonalInformationForm";
import PhysicalInfoForm from "../CoreForm/PhysicalInformationForm";
import SkillsForm from "../CoreForm/SkillsForm";
//Manpower Forms
import EmploymentInfoForm from "../ManpowerForm/EmploymentInfoForm";
import SalaryDetailForm from "../ManpowerForm/SalaryDetailForm";
import ManpowerReviewForm from "../ManpowerForm/ManpowerReviewForm";
import PropTypes from "prop-types";

import { useStore } from "../../../app/stores/store";

const Manpower = ({ onSubmit }) => {
  Manpower.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const { employeeStore } = useStore();
  const {
    setPersonalInfo,
    personalInfoFormData,
    setPhysicalInfo,
    physicalInfoFormData,
    setSkillInfo,
    skillsInfoFormData,
    setManpowerEmploymentInfo,
    manpowerEmploymentInforFormData,
    setSalaryDetailInfo,
    manpowerSalaryDetailsFormData,
    setManpowerFormCurrentStep,
    manPowerFormCurrentStep
  } = employeeStore;

  const handlePersonalInfo = (values) => {
    setManpowerFormCurrentStep(manPowerFormCurrentStep + 1);
    setPersonalInfo(values);
  };

  const handlePhysicallInfo = (values) => {
    setManpowerFormCurrentStep(manPowerFormCurrentStep + 1);
    setPhysicalInfo(values);
  };

  const handleSkilllInfo = (values) => {
    setManpowerFormCurrentStep(manPowerFormCurrentStep + 1);
    setSkillInfo(values);
  };

  const handleEmployInfo = (values) => {
    setManpowerFormCurrentStep(manPowerFormCurrentStep + 1);
    setManpowerEmploymentInfo(values);
  };

  const handleSalaryDetailInfo = (values) => {
    setManpowerFormCurrentStep(manPowerFormCurrentStep + 1);
    setSalaryDetailInfo(values);
  };

  const handlePrev = () => {
    setManpowerFormCurrentStep(manPowerFormCurrentStep - 1);
  };

  const getPreviousData = (step) => {
    if (step === 1) {
      // For PhysicalInfoForm
      return personalInfoFormData; // Retrieve the personalInfoFormData from the store
    } else if (step === 2) {
      return physicalInfoFormData;
    } else if (step === 3) {
      return skillsInfoFormData;
    } else if (step === 4) {
      return manpowerEmploymentInforFormData;
    } else if (step === 5) {
      return manpowerSalaryDetailsFormData;
    }
    // Add similar logic for other steps as needed
    return {};
  };
  return (
    <>
      <Steps current={manPowerFormCurrentStep}></Steps>
      {manPowerFormCurrentStep === 0 && (
        <PersonalInformationForm
          onNext={handlePersonalInfo}
          formData={getPreviousData(1)}
        />
      )}
      {manPowerFormCurrentStep === 1 && (
        <PhysicalInfoForm
          onNext={handlePhysicallInfo}
          onPrev={handlePrev}
          formData={getPreviousData(2)}
        />
      )}
      {manPowerFormCurrentStep === 2 && (
        <SkillsForm
          onNext={handleSkilllInfo}
          onPrev={handlePrev}
          formData={getPreviousData(3)}
        />
      )}
      {manPowerFormCurrentStep === 3 && (
        <EmploymentInfoForm
          onNext={handleEmployInfo}
          onPrev={handlePrev}
          formData={getPreviousData(4)}
        />
      )}
      {manPowerFormCurrentStep === 4 && (
        <SalaryDetailForm
          onNext={handleSalaryDetailInfo}
          onPrev={handlePrev}
          formData={getPreviousData(5)}
        />
      )}
        {manPowerFormCurrentStep === 5 && (
        <ManpowerReviewForm
          onNext={handleSalaryDetailInfo}
          onPrev={handlePrev}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default observer(Manpower);
