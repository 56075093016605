import React, { useState } from "react";
import { ConfigProvider, Modal } from "antd";
//import { BirthdayRecord } from "../Birthdays";
import { useCurrentBirthday } from "../../../../app/hooks/useCurrentBirthday";

const BirthdayViewAllBtn = () => {
  const {data} = useCurrentBirthday();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            /* here is your global tokens */
            borderRadiusLG: 35,
          },
        }}
      >
        <p className="view-all-btn" onClick={showModal}>
          View All
        </p>
        <Modal
          footer={null}
          closable={false}
          width={600}
          open={isModalOpen}
          onCancel={handleCancel}
          className="view-all-modal-container"
        >
          <div className="modal-view-header">
            <h3>Birthdays</h3>
          </div>
          <div className="modal-photo-container">
            {data.map((birthday) => {
              return (
                <div key={birthday.id} className="modal-person-photo">
                  <img
                    src={birthday.profileImagePath}
                    alt={birthday.name}
                    width={78}
                    height={78}
                    style={{ borderRadius: "50%" }}
                  />
                  <div>
                    <h5>{birthday.firstName} {birthday.lastName}</h5>
                    <h6>{`${birthday.age} years old`} </h6>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default BirthdayViewAllBtn;
