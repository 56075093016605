import React from "react";
import { Row, Col, Layout } from "antd";

import LeaveBody from "../../components/leave/LeaveBody";

const Leave = () => {
  return (
    <>
      <Layout style={{ minHeight: "80vh", padding: "20px" }}>
        <Row justify={"center"}>
          <Col>
            <LeaveBody />
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default Leave;