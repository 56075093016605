import { makeAutoObservable, runInAction, toJS } from "mobx";
import { login } from "../services/auth";

export default class AuthStore {
  currentUser = null;
  isLoggedIn = false;
  isLoading = false;
  accountInfo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (bool) => {
    runInAction(() => {
      this.isLoading = bool;
    });
  };

  setIsLoggedIn = (bool) => {
    runInAction(() => {
      this.isLoggedIn = bool;
    });
  };

  setLogin = async (values) => {
    //this.setLoading(true);
    runInAction(async () => {
      try {
        const res = await login(values);
        this.currentUser = res;
        console.log(toJS(this.currentUser));
        this.setLoading(false);
        this.setIsLoggedIn(true);
        this.loadAccountInfo();
      } catch (error) {
        console.log(error);
        this.setLoading(false);
      }
    });
  };

  loadAccountInfo = async () => {
    try {
      const accountInfo = null;
      runInAction(() => {
        this.accountInfo = accountInfo;
      });
    } catch (error) {
      console.log(error);
    }
  };
}
