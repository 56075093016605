import { makeAutoObservable, runInAction } from "mobx";

export default class EventRequestStore {
  isAddEventModalVisible = false;
  announcementId = "";

  image = "";
  fileList = [];

  uploadPhotoObject = {
    annoucementId : this.announcementId,
    "file": this.image,
  }

  uploadPhotoModal = false;

  _data = [
    {
      id: 1,
      jo: "8478235354",
      eventName: "SPOTIFY RAVE 2023",
      totalMan: "20",
      pm: "Diaz. J",
      dateReq: "July 15, 2023",
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  setIsModalVisible = (bool) => {
    runInAction(() => {
      this.isAddEventModalVisible = bool;
    });
  };

  updateUploadPhotoObject = () => {
    runInAction(()=>{
      this.uploadPhotoObject = {
        annoucementId : this.announcementId,
        "file": this.image,
      }
    })
  }

  setAnnouncementId = (id) => {
    runInAction(()=>{
      this.announcementId = id;
    })
  }

  setFileList = (value) => {
    runInAction(() => {
      this.fileList = value;
    });
  };

  setImage = (filePath) => {
    runInAction(() => {
      this.image = filePath;
      console.log(filePath);
      this.updateUploadPhotoObject();
    });
  };

  setUpdatePhotoModalVisible = (bool) => {
    runInAction(()=>{
      this.uploadPhotoModal = bool;
    })
  }
}