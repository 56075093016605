import { useState } from "react";
import { Modal, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CoreForm from "./Core";
import Manpower from "./Manpower";
import Pic from "../../../app/assets/images/core.png";
import Img from "../../../app/assets/images/manpower-img.png";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import PropTypes from "prop-types";
import UploadPhotoModal from "../modal/UploadPhotoModal";

const AddEmployeeBtn = ({ onSubmit }) => {
  AddEmployeeBtn.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  //const [subManModalVisible, setSubMaModalVisible] = useState(false);
  const { employeeStore, modalStore } = useStore();
  const {
    setDataBackToEmpty,
    setManpowerFormCurrentStep,
    setCoreFormCurrentStep,
  } = employeeStore;
  const {
    subCoreModalVisible,
    setSubCoreModalVisible,
    subManModalVisible,
    setSubMaModalVisible,
    uploadPhotoModal,
    setUpdatePhotoModalVisible,
  } = modalStore;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showCoreModal = () => {
    setIsModalVisible(false); // Close the main modal when opening the submodal
    setSubCoreModalVisible(true);
  };

  const hideCoreModal = () => {
    setSubCoreModalVisible(false);
    setDataBackToEmpty();
    setCoreFormCurrentStep(0);
  };

  const showManpowerModal = () => {
    setIsModalVisible(false); // Close the main modal when opening the submodal
    setSubMaModalVisible(true);
  };

  const hideManpowerModal = () => {
    setSubMaModalVisible(false);
    setDataBackToEmpty();
    setManpowerFormCurrentStep(0);
  };

  const hideModal = () => {
    setUpdatePhotoModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: "#F78D2E" }}
        onClick={showModal}
      >
        <PlusOutlined />
        ADD NEW EMPLOYEE
      </Button>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
      >
        <h3 className="employee-modal-title">
          ADD NEW <span style={{ color: "#FF7201" }}>EMPLOYEE</span>
        </h3>
        <Row gutter={32} justify={"center"} style={{ marginTop: "10vh" }}>
          <Col>
            <button className="core-btn" onClick={showCoreModal}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={Pic} alt="Your Logo" style={{ width: "130px" }} />
                <span style={{ color: "#FFFFFF", fontSize: 30 }}>CORE</span>
              </div>
            </button>
          </Col>
          <Col>
            <button className="manpower-btn" onClick={showManpowerModal}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={Img} alt="Your Logo" style={{ width: "130px" }} />
                <span style={{ color: "#FFFFFF", fontSize: 30 }}>MANPOWER</span>
              </div>
            </button>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={subCoreModalVisible}
        onCancel={hideCoreModal}
        footer={null}
        width={700}
      >
        <h3 className="employee-modal-title">
          ADD NEW <span style={{ color: "#FF7201" }}>EMPLOYEE</span>
        </h3>
        <CoreForm onSubmit={onSubmit} />
      </Modal>
      <Modal
        open={subManModalVisible}
        onCancel={hideManpowerModal}
        footer={null}
        width={700}
      >
        <h3 className="employee-modal-title">
          ADD NEW <span style={{ color: "#FF7201" }}>EMPLOYEE</span>
        </h3>
        <Manpower onSubmit={onSubmit} />
      </Modal>
      <Modal
        open={uploadPhotoModal}
        onCancel={hideModal}
        footer={null}
        width={700}
      >
        <h3 className="employee-modal-title">
          UPLOAD <span style={{ color: "#FF7201" }}>EMPLOYEE PHOTO</span>
        </h3>
        <UploadPhotoModal />
      </Modal>
    </>
  );
};

export default observer(AddEmployeeBtn);
