import { ExportOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { Row, Col } from "antd";
import ExportList from "../../tables/ExportList";

const ExportBtn = () => {
  const {attendaceStore} = useStore();
  const {isExportModalOpen, setIsExportModalOpen} = attendaceStore;
  const showModal = () => {
    setIsExportModalOpen(true);
  };
  const handleCancel = () => {
    setIsExportModalOpen(false);
  };
  
  return (
    <>
      <Button
        onClick={showModal}
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
      >
        <ExportOutlined />
        EXPORT
      </Button>

      <Modal
        footer={null}
        style={{ top: 110 }}
        width={900}
        open={isExportModalOpen}
        onCancel={handleCancel}
        // destroyOnClose
      >
        <Row justify={"space-between"}>
          <Col>
            <h3>
              Export <span style={{ color: "#FF7201" }}>CSV</span>
            </h3>
          </Col>
        </Row>
        
        <ExportList />
      </Modal>
    </>
  );
};

export default observer(ExportBtn);
