import useSWR from "swr";

import { api } from "../services";

const BASE_URL = "/system-admin/shift-codes";

export const useShiftCode = (query = {}) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);
  const createShift = async (data) => {
    const shiftCode = await api.post(BASE_URL, data);
    return shiftCode;
  };
  const update = async (data) => {
    const shiftCode = await api.put(BASE_URL, data);
    return shiftCode;
  };

  return {
    isLoading,
    data: data,
    count: data?.length,
    createShift,
    update,
    mutate,
  };
};
