import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";

const UploadBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        onClick={showModal}
        style={{
          background: "#FF7201",
          color: "#FFFFFF",
          borderRadius: "50px",
        }}
      >
        <CloudUploadOutlined />
        UPLOAD
      </Button>

      <Modal
        footer={null}
        closable={false}
        style={{ top: 110 }}
        width={900}
        open={isModalOpen}
        onCancel={handleCancel}
        className="view-all-modal-container"
        styles={{}}
      >
        <div></div>
      </Modal>
    </>
  );
};

export default UploadBtn;
