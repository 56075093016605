import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Row, Col } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "WEEKLY",
      color: "white",
      font: {
        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        size: 23,
      },
    },
  },
  scales: {
    x: {
      title: {
        color: "white", // X-axis label color
      },
      ticks: {
        color: "white", // X-axis ticks color
      },
    },
    y: {
      title: {
        color: "white", // Y-axis label color
      },
      ticks: {
        color: "white", // Y-axis ticks color
      },
    },
  },
};

const labels = [
  "Jan 1-7 2023",
  "Jan 8-24 2023",
  "Jan 15-21 2023",
  "Jan 22-28 2023",
];

export const data = {
  labels,
  datasets: [
    {
      label: "",
      data: [80, 120, 160, 140, 200, 180, 250, 10],
      borderColor: "white",
      backgroundColor: "white",
    },
  ],
};

const WeeklyAttendance = () => {
  return (
    <Row>
      <Col span={24}>
        <div className="weekly-attendance-container">
          <Bar options={options} data={data} />
        </div>
      </Col>
    </Row>
  );
};

export default WeeklyAttendance;
