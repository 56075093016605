import React from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useStore } from "../../../app/stores/store";
import { useEmployee } from "../../../app/hooks/useEmployee";
const { Option } = Select;
import { toast } from "react-toastify";

const UpdatePersonalInfoForm = ({ onSubmit }) => {
  UpdatePersonalInfoForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const [form] = Form.useForm();
  //form.setFieldsValue(formData);
  const { update } = useEmployee();
  const { updateEmployeeStore, modalStore } = useStore();
  const { personalInfoFormData, setPersonalInfo } = updateEmployeeStore;
  const {
    setUpdateEmployeeDetailsModalVisible,
    setUpdatePersonalInfoModalVisible,
  } = modalStore;

  const handleBack = () => {
    setUpdateEmployeeDetailsModalVisible(true);
    setUpdatePersonalInfoModalVisible(false);
  }

  const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      callback();
    } else {
      callback("Please enter a valid email address");
    }
  };
  const birthDateValue = personalInfoFormData?.employeePersonalProfile
    ?.birthDate
    ? personalInfoFormData?.employeePersonalProfile?.birthDate.split("T")[0]
    : undefined;
  const initialValues = {
    ...personalInfoFormData,
    birthDate: birthDateValue,
    sssNumber: personalInfoFormData?.employeePersonalProfile?.sssNumber,
    address: personalInfoFormData?.employeePersonalProfile?.presentAddress,
    philHealthNum:
      personalInfoFormData?.employeePersonalProfile?.philhealthNumber,
    gender: personalInfoFormData?.employeePersonalProfile?.gender,
    contactNum: personalInfoFormData?.phoneNumber,
    preference:
      personalInfoFormData?.employeePersonalProfile?.genderPreferenceRemarks,
    civilStatus: personalInfoFormData?.employeePersonalProfile?.civilStatus,
    pagIbigNum: personalInfoFormData?.employeePersonalProfile?.pagIbigNumber,
    tinNum: personalInfoFormData?.employeePersonalProfile?.tinNumber,
    guardianName:
      personalInfoFormData?.employeePersonalProfile?.emergencyContactName,
    guardianContactNum:
      personalInfoFormData?.employeePersonalProfile?.emergencyContactNumber,
    restriction:
      personalInfoFormData?.employeePersonalProfile?.restrictionRemarks,
  };

  const handleSubmit = async () => {
    try {
      // Get the current form values
      const formValues = form.getFieldsValue();

      // Update only the changed fields in personalInfoFormData
      const updatedFormData = {
        ...personalInfoFormData,
        firstName: formValues.firstName,
        middleName: formValues.middleName,
        lastName: formValues.lastName,
        email: formValues.email,
        employeePersonalProfile: {
          ...personalInfoFormData.employeePersonalProfile,
          birthDate:
            formValues.birthDate ||
            personalInfoFormData.employeePersonalProfile.birthDate,
          sssNumber:
            formValues.sssNumber ||
            personalInfoFormData.employeePersonalProfile.sssNumber,
          tinNumber:
            formValues.tinNum ||
            personalInfoFormData.employeePersonalProfile.tinNumber,
          presentAddress:
            formValues.address ||
            personalInfoFormData.employeePersonalProfile.presentAddress,
          philhealthNumber:
            formValues.philHealthNum ||
            personalInfoFormData.employeePersonalProfile.philhealthNumber,
          gender:
            formValues.gender ||
            personalInfoFormData.employeePersonalProfile.gender,
          genderPreferenceRemarks:
            formValues.preference ||
            personalInfoFormData.employeePersonalProfile
              .genderPreferenceRemarks,
          civilStatus:
            formValues.civilStatus ||
            personalInfoFormData.employeePersonalProfile.civilStatus,
          pagIbigNumber:
            formValues.pagIbigNum ||
            personalInfoFormData.employeePersonalProfile.pagIbigNumber,
          emergencyContactName:
            formValues.guardianName ||
            personalInfoFormData.employeePersonalProfile.emergencyContactName,
          emergencyContactNumber:
            formValues.guardianContactNum ||
            personalInfoFormData.employeePersonalProfile.emergencyContactNumber,
          restrictionRemarks:
            formValues.restriction ||
            personalInfoFormData.employeePersonalProfile.restrictionRemarks,
        },
        // Update other top-level fields similarly
        phoneNumber: formValues.contactNum || personalInfoFormData.phoneNumber,
      };

      // Update the store with the new data using setPersonalInfo
      setPersonalInfo(updatedFormData);

      await update(updatedFormData)
        .then(() => {
          setUpdateEmployeeDetailsModalVisible(true);
          setUpdatePersonalInfoModalVisible(false);
          onSubmit();
          toast.success("Successfully Update Personal Information", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            // Extract error titles and display them in toasts
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            // Display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });

      // Perform your form submission logic here
      // For example: await someApiCall(updatedFormData);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={initialValues}
      >
        <h2 className="core-form-title">
          I. <span style={{ color: "#FF7201" }}>Personal</span> Information
        </h2>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              label="First Name:"
              name="firstName"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Middle Name:"
              name="middleName"
              rules={[
                { required: true, message: "Please enter your middle name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Last Name:"
              name="lastName"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Birth Date:"
              name="birthDate"
              rules={[
                { required: true, message: "Please enter your birth date" },
              ]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Email:"
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { validator: validateEmail },
              ]}
            >
              <Input type="email" name="email" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="SSS NO:"
              name="sssNumber"
              rules={[
                { required: true, message: "Please enter your SSS number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"}>
          <Col span={17}>
            <Form.Item
              label="Complete Address:"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter your complete address",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="PHILHEALTH NO:"
              name="philHealthNum"
              rules={[
                {
                  required: true,
                  message: "Please enter your Philhealth number",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} justify={"space-between"}>
          <Col span={8}>
            <Form.Item
              label="Gender:"
              name="gender"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Contact NO:"
              name="contactNum"
              rules={[
                { required: true, message: "Please enter your contact number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Preference remarks:"
              name="preference"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="ex: Boyish" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"} gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Civil Status"
              name="civilStatus"
              rules={[
                { required: true, message: "Please enter your civil status" },
              ]}
            >
              <Select allowClear>
                <Option value="Single">Single</Option>
                <Option value="Married">Married</Option>
                <Option value="Widowed">Widowed</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="PAGIBIG NO:"
              name="pagIbigNum"
              rules={[
                { required: true, message: "Please enter your PAGIBIG number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="TIN NO:"
              name="tinNum"
              rules={[
                { required: true, message: "Please enter your TIN number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"}>
          <Col span={10}>
            <Form.Item
              label="In Case of Emergency"
              name="guardianName"
              rules={[{ required: true, message: "Please enter contact name" }]}
            >
              <Input placeholder="Contact Name" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Contact Number"
              name="guardianContactNum"
              rules={[
                { required: true, message: "Please enter contact number" },
              ]}
            >
              <Input placeholder="Contact No." type="number" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Restriction Remarks:"
              name="restriction"
              rules={[
                {
                  required: true,
                  message: "Please enter your restriction remarks",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Update
              </Button>
            </Col>
            <Col>
            <Button
                type="default"
                onClick={handleBack}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(UpdatePersonalInfoForm);
