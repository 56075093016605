import useSWR from "swr";
import { api } from "../services";

const BASE_URL = "/announcements";
const PATCH_URL = "/announcements/upload-image";

export const useAnnouncement = ( query = {} ) => {
  const qs = new URLSearchParams(query).toString();
  const { data, isLoading, mutate } = useSWR(`${BASE_URL}?${qs}`, api.get);

  const create = async (data) => {
    const announcement = await api.post(BASE_URL, data);
    return announcement;
  };

  const patch = async (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const announcement = await api.post(PATCH_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return announcement;
  };

  const find = async (query) => {
    const announcement = await api.get(`${BASE_URL}/find?search=${query}`);
    return announcement;
  };

  return {
    isLoading,
    data: data,
    count: data?.length,
    create,
    patch,
    find,
    mutate,
  };
};