import React from "react";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import AddEventForm from "../form/AddEventForm";
import { useStore } from "../../../app/stores/store";
import UploadAnnouncementPhotoModal from "../../employees/modal/UploadAnnouncementPhotoModal";
import { useAnnouncement } from "../../../app/hooks/useAnnouncement";

const CreateEventButton = () => {
  const { eventRequestStore } = useStore();
  const {
    setIsModalVisible,
    isAddEventModalVisible,
    uploadPhotoModal,
    setUpdatePhotoModalVisible,
  } = eventRequestStore;
  const Date = ('2023-1-20');

  const { mutate } = useAnnouncement({
    startDate: Date,
    pageNumber: 0,
    pageSize: 9999,
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const handleModalClosed = () => {
  //   setEditUser(null);
  // };
  const hideModal = () => {
    setUpdatePhotoModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: "#F78D2E", margin: 50, marginRight: 5 }}
        onClick={showModal}
      >
        <PlusOutlined />
        ADD NEW EVENT
      </Button>
      <Modal
        open={isAddEventModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
        afterClose={() => console.log("Modal Closed")}
      >
        <h3 className="employee-modal-title">
          CREATE <span style={{ color: "#FF7201" }}>ANNOUNCEMENT / EVENT</span>
        </h3>
        <AddEventForm
          onSubmit={() => {
            mutate();
          }}
        />
      </Modal>
      <Modal
        open={uploadPhotoModal}
        onCancel={hideModal}
        footer={null}
        width={700}
      >
        <h3 className="employee-modal-title">
          UPLOAD <span style={{ color: "#FF7201" }}>ANNOUNCEMENT PHOTO</span>
        </h3>
        <UploadAnnouncementPhotoModal />
      </Modal>
    </>
  );
};

export default observer(CreateEventButton);
