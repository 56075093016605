import TotalLate from "../../components/dashboard/summary-count/TotalLate";
import TotalAbsent from "../../components/dashboard/summary-count/TotalAbsent";
import TotalPresent from "../../components/dashboard/summary-count/TotalPresent";
import TotalEmployees from "../../components/dashboard/summary-count/TotalEmployees";
import TotalAttendanceReport from "../../components/dashboard/charts/TotalAttendanceReport";
import { Row, Col, ConfigProvider } from "antd";
import WeeklyAttendance from "../../components/dashboard/charts/WeeklyAttendance";
import DailyAttendance from "../../components/dashboard/charts/DailyAttendance";
import AttendanceGallery from "../../components/dashboard/attendance-gallery/AttendanceGallery";
import Birthdays from "../../components/dashboard/right-side/Birthdays";
import EmployeeAnniversary from "../../components/dashboard/right-side/EmployeeAnniversary";
import LatestNews from "../../components/dashboard/right-side/LatestNews";
import RequestByAdmin from "../../components/dashboard/tables/RequestByAdmin";
import Documents from "../../components/dashboard/tables/Documents";
import RecentNotifications from "../../components/dashboard/tables/RecentNotifications";
import { useEmployeeDashboardCount } from "../../app/hooks/useEmployeesCount";

function Dashboard() {
  const { data: stats } = useEmployeeDashboardCount();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#EFEEEE",
          margin: 16,
          boxShadowSecondary:
            "0 4px 8px 0 rgba(0, 0, 0.5, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          padding: 20,
          borderRadiusLG: 8,
        },
        components: {
          Table: {
            // Seed Token
            headerBg: "#ff7201",
            headerColor: "white",
            headerSplitColor: "#ff7201",
          },
        },
      }}
    >
      <div className="dashboard-container">
        <div>
          <h2>HI. Activations Advertising Inc.!</h2>
        </div>
        <div className="dashboard-items">
          <div className="summary-count-container">
            <TotalEmployees count={stats?.totalEmployee ?? 0} />
            <TotalPresent count={stats?.totalPresent ?? 0} />
            <TotalAbsent count={stats?.totalAbsent ?? 0} />
            <TotalLate count={stats?.totalLate ?? 0} />
          </div>
          <div>
            <Row>
              <Col>
                <TotalAttendanceReport />

                <WeeklyAttendance />

                <DailyAttendance />

                <AttendanceGallery />
              </Col>
              <Col>
                <Birthdays />
                <EmployeeAnniversary />
                <LatestNews />
              </Col>
            </Row>
          </div>
          <div>
            <RequestByAdmin />
            <Documents />
            <RecentNotifications />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default Dashboard;
