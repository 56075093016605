import React from "react";
import { Link } from "react-router-dom";
import { Row, Button, Col, Card, Modal } from "antd";
import { EditFilled } from "@ant-design/icons";
import { useAnnouncement } from "../../../app/hooks/useAnnouncement";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import AddEventForm from "../../event-request/form/AddEventForm";
import UploadAnnouncementPhotoModal from "../../employees/modal/UploadAnnouncementPhotoModal";

const { Meta } = Card;

const LatestNews = () => {
  const { eventRequestStore } = useStore();
  const {
    setIsModalVisible,
    isAddEventModalVisible,
    uploadPhotoModal,
    setUpdatePhotoModalVisible,
  } = eventRequestStore;
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${
    currentDate.getMonth() + 1
  }-${currentDate.getDate()}`;

  const showModal = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setUpdatePhotoModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { data, mutate } = useAnnouncement({
    startDate: formattedDate,
    pageNumber: 0,
    pageSize: 3,
    sort: "date_descend",
  });

  const AnnouncementCard = (item) => {
    return (
      <Link to={`/events/${item?.item?.id}`}>
        <Card
          hoverable
          style={{
            width: "100%",
            position: "relative",
          }}
          cover={
            <img
              alt="example"
              src={item.item.imagePath || "https://picsum.photos/200/300"}
            />
          }
        >
          <Meta title={item.item.name} style={{ textAlign: "center" }} />
        </Card>
      </Link>
    );
  };

  return (
    <div className="latest-news-container">
      <Row justify={"space-between"}>
        <Col>
          <h2>
            Latest News & <br /> Announcements
          </h2>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              backgroundColor: "#F78D2E",
              marginTop: "20%",
              marginLeft: "5%",
              borderRadius: "50px",
            }}
            onClick={showModal}
          >
            <EditFilled />
            CREATE
          </Button>
        </Col>
      </Row>
      <div className="card-wrapper">
        {data?.map((item) => (
          <AnnouncementCard key={item.id} item={item} />
        ))}
      </div>
      <div style={{ marginTop: 30, textAlign: "center" }}>
        <Link
          type="primary"
          style={{
            backgroundColor: "#F78D2E",
            borderRadius: "50px",
            padding: "5px 25px",
          }}
          to={"event-request"}
        >
          <span style={{ color: "#fff" }}>View All</span>
        </Link>
      </div>
      <Modal
        open={isAddEventModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
        afterClose={() => console.log("Modal Closed")}
      >
        <h3 className="employee-modal-title">
          CREATE <span style={{ color: "#FF7201" }}>ANNOUNCEMENT / EVENT</span>
        </h3>
        <AddEventForm
          onSubmit={() => {
            mutate();
          }}
        />
      </Modal>
      <Modal
        open={uploadPhotoModal}
        onCancel={hideModal}
        footer={null}
        width={700}
      >
        <h3 className="employee-modal-title">
          UPLOAD <span style={{ color: "#FF7201" }}>ANNOUNCEMENT PHOTO</span>
        </h3>
        <UploadAnnouncementPhotoModal />
      </Modal>
    </div>
  );
};

export default observer(LatestNews);
