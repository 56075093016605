import React, { useState } from "react";
import { ConfigProvider, Modal } from "antd";
import { useCurrentAnniversary } from "../../../../app/hooks/useCurrentAnniversary";

const AnnivViewAllBtn = () => {
  const {data} = useCurrentAnniversary();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            /* here is your global tokens */
            borderRadiusLG: 35,
          },
        }}
      >
        <p className="view-all-btn" onClick={showModal}>
          View All
        </p>
        <Modal
          footer={null}
          closable={false}
          width={600}
          open={isModalOpen}
          onCancel={handleCancel}
          className="view-all-modal-container"
        >
          <div className="modal-view-header">
            <h3>Employment Anniversary</h3>
          </div>
          <div className="modal-photo-container">
            {data.map((anniversary) => {
              return (
                <div key={anniversary.id} className="modal-person-photo">
                  <img
                    src={anniversary.profileImagePath}
                    alt={anniversary.firstName}
                    width={78}
                    height={78}
                    style={{ borderRadius: "50%" }}
                  />
                  <div>
                    <h3>{anniversary.firstName} {anniversary.lastName}</h3>
                    <h5>{`Since ${formatDate(anniversary.dateHired)}`}</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default AnnivViewAllBtn;
