import React from "react";
import { Upload, message, Button } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";


const UploadAnnouncementImage = () => {
  const { eventRequestStore } = useStore();
  const { setImage, fileList, setFileList } = eventRequestStore;

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isSizeAccepted = file.size / 1024 / 1024 < 20; // 20MB
    if (!isSizeAccepted) {
      message.error("Image must be smaller than 20MB!");
      return false;
    }

    return true;
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];

    // Limit the number of uploaded files
    newFileList = newFileList.slice(-1);

    // Update the state
    setFileList(newFileList);
  };

  const customRequest = ({ file, onSuccess }) => {
    // Extract the file path from the file object
    //const filePath = URL.createObjectURL(file);
    setImage(file);

    // Send the file path to your server or store it as needed

    // Simulating a delay for demonstration purposes
    setTimeout(() => {
      onSuccess();
      message.success(`${file.name} file uploaded successfully.`);
    }, 1000);
  };

  return (
    <>
      <Upload
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        fileList={fileList}
        showUploadList={false} // hide default file list
      >
        <Button
          type="primary"
          style={{
            backgroundColor: "#2C2C2C",
            borderRadius: "83px",
            width: "123.87px",
          }}
        >
          Upload
        </Button>
      </Upload>
    </>
  );
};

export default observer(UploadAnnouncementImage);
