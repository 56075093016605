import { createContext, useContext } from 'react';

import EmployeeStore from './employeeStore';
import UserManagementStore from './userManagementStore';
import ModalStore from './modalStore';
import EventRequestStore from './eventRequestStore';
import AuthStore from './authStore';
import UpdateEmployeeStore from './updateEmployeeStore';
import EventsStore from './eventsStore';
import AttendanceStore from './attendanceStore';
import ShiftStore from './shiftsStore';
import TimeStore from './timeStore';

const store = {
  employeeStore: new EmployeeStore(),
  modalStore: new ModalStore(),
  userManagementStore: new UserManagementStore(),
  eventRequestStore: new EventRequestStore(),
  authStore: new AuthStore(),
  updateEmployeeStore: new UpdateEmployeeStore(),
  eventsStore: new EventsStore(),
  attendaceStore: new AttendanceStore(),
  shiftStore: new ShiftStore(),
  timeStore: new TimeStore()
}

export default store;

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}