import { makeAutoObservable, runInAction } from "mobx";
import { getEmployeeById } from "../services/auth/getEmployee";

export default class UpdateEmployeeStore {
  personalInfoFormData = {};
  employeeId = '';
  constructor() {
    makeAutoObservable(this);
  }

  setPersonalInfo = (values) => {
    runInAction(() => {
      // Update the personalInfoFormData with the provided values
      this.personalInfoFormData = { ...this.personalInfoFormData, ...values };
    });
  };

  setGetEmployeeId = async (values) => {
    runInAction(async () => {
      this.employeeId = values;
      const response = await getEmployeeById(values);
      this.personalInfoFormData = { ...this.personalInfoFormData, ...response };
    });
  };
}
