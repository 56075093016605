import React from "react";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import AddAccountForm from "../form/AddAccountForm";
import { useStore } from "../../../app/stores/store";
import GetEmployeeNumberForm from "../form/GetEmployeeNumberForm";

const AddAccountButton = () => {
  const {userManagementStore} = useStore();
  const {isAddAccountModalVisible, setIsModalVisible, setEditUser, isUpdateRoleFormVisible,setUpdateRoleFormVisibilty} = userManagementStore;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUpdateRoleFormVisibilty(false);
  };

  const handleModalClosed = () => {
    setEditUser(null);
  };
  return (
    <>
      <Button
        type="primary"
        style={{ backgroundColor: "#F78D2E", margin: 50, marginRight: 5 }}
        onClick={showModal}
      >
        <PlusOutlined />
        ADD NEW ACCOUNT
      </Button>
      <Modal
        open={isAddAccountModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        destroyOnClose
        afterClose={handleModalClosed}
      >
        <h3 className="employee-modal-title">
          ADD NEW <span style={{ color: "#FF7201" }}>ACCOUNT</span>
        </h3>
        { isUpdateRoleFormVisible ?  <AddAccountForm/> : <GetEmployeeNumberForm/>}
       
      </Modal>
    </>
  );
};

export default observer(AddAccountButton);
