import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Row, Col, Checkbox } from "antd";

const CustomShiftList = () => {
  const { shiftStore } = useStore();
  const { shiftCodeData } = shiftStore;
  return (
    <>
      <div>
        {shiftCodeData && Array.isArray(shiftCodeData.shiftCodeItems) ? (
          shiftCodeData.shiftCodeItems.map((item, index) => (
            <div
              key={index}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  marginBottom: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  height: "5vh",
                  width: "100%",
                  alignContent: "center",
                  backgroundColor: `${"#fff1e6"}`,
                }}
              >
                <Checkbox
                  style={{ marginLeft: 20, width: "85%" }}
                >
                  <Row gutter={20}>
                    <Col style={{ alignContent: "center", width: "100px" }}>
                      <span style={{ color: `${"black"}` }}>
                        {item.dayOfWeekName}
                      </span>{" "}
                    </Col>
                    <Col>
                      <div
                        style={{
                          backgroundColor: "#FF7201",
                          height: "5vh",
                          padding: "0px 5px",
                          display: "inline-block",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "white" }}>
                        {item.timeIn}
                        </span>
                        <button
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            color: "white",
                            fontSize: "20px",
                          }}
                        >
                          -
                        </button>
                        <span style={{ color: "white" }}>
                        {item.timeOut}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Checkbox>
              </div>
            </div>
          ))
        ) : (
          <div>Default TimeIn</div>
        )}
      </div>
    </>
  );
};

export default observer(CustomShiftList);
