import React from "react";
import { Upload, message } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import Pic from "../../app/assets/images/upload-img.png";

const UploadImage = () => {
  const { employeeStore } = useStore();
  const { setImage, fileList, setFileList } = employeeStore;

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }

    const isSizeAccepted = file.size / 1024 / 1024 < 20; // 20MB
    if (!isSizeAccepted) {
      message.error("Image must be smaller than 20MB!");
      return false;
    }

    return true;
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];

    // Limit the number of uploaded files
    newFileList = newFileList.slice(-1);

    // Update the state
    setFileList(newFileList);
  };

  const customRequest = ({ file, onSuccess }) => {
    // Extract the file path from the file object
    //const filePath = URL.createObjectURL(file);
    setImage(file);

    // Send the file path to your server or store it as needed

    // Simulating a delay for demonstration purposes
    setTimeout(() => {
      onSuccess();
      message.success(`${file.name} file uploaded successfully.`);
    }, 1000);
  };

  return (
    <>
      <Upload
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        fileList={fileList}
        showUploadList={false} // hide default file list
      >
        <img src={Pic} alt="" width={170} />
      </Upload>
    </>
  );
};

export default observer(UploadImage);
