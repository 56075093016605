import React, { useState } from "react";
import { Button, Modal } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useLeaveApplication } from "../../../../app/hooks/useLeaveApplications";
import { toast } from "react-toastify";

const ViewRequestBtn = ({ record, onSubmit }) => {
  ViewRequestBtn.propTypes = {
    record: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { update} = useLeaveApplication();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    try {
      const updateLeaveStatusObj = {
        leaveApplicationId: record?.id,
        status: 1,
      };
      await update(updateLeaveStatusObj)
        .then(() => {
          onSubmit();
          toast.success("Successfully Update Status", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsModalOpen(false);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    try {
      const updateLeaveStatusObj = {
        leaveApplicationId: record?.id,
        status: 2,
      };
      await update(updateLeaveStatusObj)
        .then(() => {
          onSubmit();
          toast.success("Successfully Update Status", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsModalOpen(false);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((errorItem) => {
              toast.error(errorItem.title, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
          } else {
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button className="view-request-btn" onClick={showModal}>
        <FileTextOutlined />
        VIEW
      </Button>
      <Modal
        footer={null}
        closable={false}
        style={{ top: 110 }}
        width={900}
        open={isModalOpen}
        className="view-request-modal-container"
      >
        <div>
          <p style={{}}>{record.reason}</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ background: "green", color: "white", margin: "50px" }}
            onClick={handleOk}
          >
            ACCEPT
          </Button>
          <Button
            type="primary"
            danger
            style={{ margin: "50px" }}
            onClick={handleCancel}
          >
            REJECT
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ViewRequestBtn;
