import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col, Space } from "antd";
import LeaveFilterBtn from "./buttons/LeaveFilterBtn";
import AttendanceLeave from "./AttendanceLeave";
import ExportBtn from "./buttons/ExportBtn";
const LeaveBody = () => {
  return (
    <>
      <Row>
        <Col span={22}>
          <Row justify={"space-between"}>
            <Col>
              <h1>Employee Leave Summary</h1>
            </Col>
            <Col>
              <Space style={{ marginTop: 20, marginLeft: "50%" }}>
                <ExportBtn />
                <LeaveFilterBtn />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify={"space-evenly"}>
            <Col>
              <AttendanceLeave />
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
};

export default observer(LeaveBody);
