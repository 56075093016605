import React, { useRef } from "react";
import { Form, Button, Row, Col, DatePicker, Input } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import DebounceSelect from "../common/DebounceSelect";
import { useEmployee } from "../../app/hooks/useEmployee";
//import { useRoles } from "../../../app/hooks/useRoles";
//import { toast } from "react-toastify";

const AttendanceFilterForm = () => {
  const [form] = Form.useForm();
  const { attendaceStore } = useStore();
  const { find } = useEmployee();
  const { setDateFilter, setIsModalOpen } = attendaceStore;
  const findRef = useRef([]);
  //const {data} =useRoles();

  // const initialValues = {
  //   ...data,
  // }

  const handleSearchEmployee = async (value) => {
    return find(value.toLowerCase()).then((response) => {
      findRef.current = response;
      return response.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
    });
  };

  const handleSubmit = async (values) => {
    try {
      console.log(values.startDate.format("YYYY-MM-DD"));
      setDateFilter(
        values.startDate.format("YYYY-MM-DD"),
        values.endDate.format("YYYY-MM-DD"),
        values.employeeId,
        //values.employeeNumber
      );
      setIsModalOpen(false);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  const handleFilter = () => {
    setDateFilter("");
    setIsModalOpen(false);
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        style={{ marginTop: 40 }}
        onFinish={handleSubmit}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Select Start Date:"
              name="startDate"
              rules={[{ required: true, message: "Select start date" }]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Select End Date:"
              name="endDate"
              rules={[{ required: true, message: "Select start date" }]}
            >
              <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Employee Name:"
              name="employeeId"
              //rules={[{ required: true, message: "Please select start date" }]}
            >
              <DebounceSelect
                // mode="multiple"
                //value={value}
                placeholder="Select Employee Name"
                fetchOptions={handleSearchEmployee}
                onChange={(newValue) => {
                  var data = findRef.current.find(
                    (item) => item.id == newValue
                  );
                  // TODO need to validate
                  form.setFieldsValue({
                    employeeId: data.id,
                    employeeNumber: data.employeeNumber,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employee Number:"
              name="employeeNumber"
              //rules={[{ required: true, message: "Please select start date" }]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button
                onClick={handleFilter}
                type="default"
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Clear all Filter
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Apply Filter
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(AttendanceFilterForm);
