import React from "react";
import { Form, Input, Button, Row, Col, Select, Checkbox, Divider } from "antd";
//import UploadImage from "../../common/UploadImage";
//import UploadReviewImage from "../../common/UploadReviewImage";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useDepartment } from "../../../app/hooks/useDepartment";
import { usePositions } from "../../../app/hooks/usePositions";
import { useRegions } from "../../../app/hooks/useRegions";
import { useCurrentUser } from "../../../app/hooks/useCurrentUser";

import AddPositionForm from "../forms/AddPositionForms";
import AddDepartmentForm from "../forms/AddDepartmentForms";

const { Option } = Select;

const ManpowerEmployInfoForm = ({ onNext, onPrev, formData }) => {
  const [form] = Form.useForm();
  form.setFieldsValue(formData);
  const { data: departmentList, mutate: mutates } = useDepartment({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { data: positionList, mutate } = usePositions({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { data: regionList } = useRegions();
  const { role } = useCurrentUser();

  ManpowerEmployInfoForm.propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
  };

  return (
    <>
      <Form form={form} onFinish={onNext} layout="vertical">
        <h2 className="core-form-title">
          II. <span style={{ color: "#FF7201" }}>Employment </span>Information
        </h2>
        <Row justify={"space-between"} gutter={20}>
          <Col span={12}>
            <Form.Item
              name="manPowerPosition"
              label="Position:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    {role.includes("Super Admin") ? (
                      <AddPositionForm
                        onSubmit={() => {
                          mutate();
                        }}
                      />
                    ) : null}
                  </>
                )}
                options={positionList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employee Number:"
              name="manPowerEmployeeNum"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date Hired:"
              name="manPowerDateHired"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Work Assignment:"
              name="manPowerWorkAss"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                placeholder="Region"
                allowClear
                options={regionList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={70}>
          <Col span={12}>
            <Form.Item
              name="manPowerDepartments"
              label="Department:"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                allowClear
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    {role.includes("Super Admin") ? (
                      <AddDepartmentForm
                        onSubmit={() => {
                          mutates();
                        }}
                      />
                    ) : null}
                  </>
                )}
                options={departmentList?.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Active or Inactive:"
              name="manPowerActiveOrInactive"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
                <Option value="Blocked">Blocked</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employment Status:"
              name="manPowerEmploymentStatus"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="JobOrder">Job Order</Option>
                <Option value="ProjectBased">Project Based</Option>
                <Option value="Regular">Regular</Option>
                <Option value="Separated">Separated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Pre Employment Requirements:"
              name="manPowerPreEmployRequirementsStatus"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="true">True</Option>
                <Option value="false">False</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Type:"
              name="manPowercontractType"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select allowClear>
                <Option value="NotApplicable">Not Applicable</Option>
                <Option value="ShortTerm">Short Term</Option>
                <Option value="LongTerm">Long Term</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"space-between"}>
          <Col>
            <Form.Item
              label="Pre Employment Requirements:"
              name="manPowerPreEmployRequirements"
              rules={[{ required: true, message: "Required" }]}
            >
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={12}>
                    <Checkbox value="Valid ID">Valid ID</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Medical Certificate">
                      MEDICAL CERTIFICATE OF FIT TO WORK
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="NBI Clearance">NBI CLEARANCE</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Police Clearance">
                      POLICE CLEARANCE
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Barangay Clearance">
                      BARANGAY CLEARANCE
                    </Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Vaccine Card">VACCINE CARD</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Health Card">HEALTH CARD</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        {/* <Row justify={"center"} style={{ marginBottom: 20 }}>
          <Col>
            <UploadImage />
          </Col>
          <Col style={{ marginTop: 20 }}>
            <h2 className="core-form-title">
              <span style={{ color: "#FF7201" }}>Upload </span>
              Profile Photo
            </h2>
            <p className="upload-img-txt">
              File Formats Accepted
              <span className="upload-img-span">PNG, JPG, JPEG</span>
            </p>
            <p className="upload-img-txt">
              Max. file size <span className="upload-img-span">20 MB</span>
            </p>
          </Col>
          <UploadReviewImage />
        </Row> */}

        <Form.Item>
          <Row gutter={20} justify={"center"}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Next
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={onPrev}
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(ManpowerEmployInfoForm);
