import React, { useRef, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select, Checkbox } from "antd";
import { observer } from "mobx-react-lite";
import DebounceSelect from "../common/DebounceSelect";
import { useEmployee } from "../../app/hooks/useEmployee";
import { useShift } from "../../app/hooks/useShift";
import { useEmployeeShift } from "../../app/hooks/useEmployeeShift";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";
import CustomShiftList from "../common/CustomShiftList";

const EmployeeShiftForm = () => {
  const [form] = Form.useForm();
  const { find } = useEmployee();
  const { create, mutate } = useEmployeeShift();
  const { data } = useShift({
    pageNumber: 0,
    pageSize: 9999,
    sort: "date_descend",
  });
  const { shiftStore } = useStore();
  const {
    setCreateEmployeeShiftModalVisible,
    setGetShiftById,
    shiftCodeData,
    remarks,
    checkboxState,
    setCheckboxState,
    check,
    setCheck,
  } = shiftStore;
  const findRef = useRef([]);

  const handleCheckboxChange = (e, value) => {
    setCheckboxState(e, value);
  };

  const handleSearchEmployee = async (value) => {
    return find(value.toLowerCase()).then((response) => {
      findRef.current = response;
      return response.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      }));
    });
  };

  const handleCreateEmployeeShiftCancel = () => {
    setCreateEmployeeShiftModalVisible(false);
  };

  const handleSubmit = async (values) => {
    console.log(values.effectiveDate, values.terminationDate);
    const employeeShiftObj = {
      employeeId: values.employeeId,
      shiftCodeId: values.shiftCodeId,
      location: "",
      longitude: "",
      latitude: "",
      status: 1,
      effectiveDate: values.effectiveDate?.format("YYYY-MM-DD"),
      terminationDate: values.terminationDate?.format("YYYY-MM-DD"),
    };
    try {
      if (values != null) {
        await create(employeeShiftObj).then(() => {
          mutate();
          setCreateEmployeeShiftModalVisible(false);
          toast.success("Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  useEffect(() => {
    // Initialize checkboxState based on remarks
    const initialCheckboxState = optionsWithDisabled.reduce((acc, option) => {
      acc[option.value] = remarks === "weekend" ? ["Saturday", "Sunday"].includes(option.value) : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].includes(option.value);
      return acc;
    }, {});
    setCheckboxState(initialCheckboxState);
  }, [remarks]);

  const optionsWithDisabled = [
    {
      label: "Monday",
      value: "Monday",
      disabled: remarks === "weekend",
    },
    {
      label: "Tuesday",
      value: "Tuesday",
      disabled: remarks === "weekend",
    },
    {
      label: "Wednesday",
      value: "Wednesday",
      disabled: remarks === "weekend",
    },
    {
      label: "Thursday",
      value: "Thursday",
      disabled: remarks === "weekend",
    },
    {
      label: "Friday",
      value: "Friday",
      disabled: remarks === "weekend",
    },
    {
      label: "Saturday",
      value: "Saturday",
      disabled: remarks === "daily",
    },
    {
      label: "Sunday",
      value: "Sunday",
      disabled: remarks === "daily",
    },
  ];

  return (
    <>
      <Form
        form={form}
        // initialValues={initialValues}
        onFinish={handleSubmit}
        layout="vertical"
        style={{ marginTop: 40 }}
      >
        <Row justify={"space-between"}>
          <Col span={10}>
            <Form.Item
              label="Employee Name:"
              name="employeeId"
              rules={[{ required: true, message: "Required" }]}
            >
              <DebounceSelect
                // mode="multiple"
                //value={value}
                placeholder="Select Employee Name"
                fetchOptions={handleSearchEmployee}
                onChange={(newValue) => {
                  var data = findRef.current.find(
                    (item) => item.id == newValue
                  );
                  // TODO need to validate
                  form.setFieldsValue({
                    employeeId: data.id,
                    employeeNumber: data.employeeNumber,
                    position: data.serviceRecords[0].positionName,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Employee Number:" name="employeeNumber">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Level:" name="position">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Schedule:"
              name="shiftCodeId"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                placeholder="Choose Schedule..."
                allowClear
                options={data?.map((item) => {
                  return {
                    value: item.id,
                    label: item.description,
                  };
                })}
                onChange={(selectedOption) => {
                  // Check if an option is selected
                  if (selectedOption !== undefined && selectedOption !== null) {
                    // Set check to true when an option is clicked
                    setGetShiftById(selectedOption);
                    setCheck(false);
                  } else {
                    // Set check to true when no option is selected
                    setCheck(true);
                  }
                }}
                onClear={() => {
                  // Set check to true when the selection is cleared
                  setCheck(true);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label=""
              name="options"
              labelAlign="left"
              style={{ marginTop: 30 }}
            >
              {remarks !== "custom" ? (
                <>
                {optionsWithDisabled.map((option) => (
                  <div
                    key={option.value}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginBottom: "8px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        height: "5vh",
                        width: "100%",
                        alignContent: "center",
                        backgroundColor: `${check ? "white" : "#fff1e6"}`,
                      }}
                    >
                      <Checkbox
                        value={option.value}
                        onChange={(e) => handleCheckboxChange(e, option.value)}
                        style={{ marginLeft: 20, width: "85%" }}
                        disabled={option.disabled}
                        checked={checkboxState[option.value]}
                      >
                        <Row gutter={20}>
                          <Col style={{ alignContent: "center", width: "100px" }}>
                            <span
                              style={{ color: `${check ? "black" : "black"}` }}
                            >
                              {option.label}
                            </span>{" "}
                          </Col>
                          <Col>
                            {remarks === "daily" &&
                              [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                              ].includes(option.value) && (
                                <>
                                  {!check && (
                                  <div
                                    style={{
                                      backgroundColor: "#FF7201",
                                      height: "5vh",
                                      padding: "0px 5px",
                                      display: "inline-block",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ color: "white" }}>
                                      {shiftCodeData &&
                                      Array.isArray(
                                        shiftCodeData.shiftCodeItems
                                      ) &&
                                      shiftCodeData.shiftCodeItems.length > 1
                                        ? shiftCodeData.shiftCodeItems[1].timeIn
                                        : "Default TimeIn"}
                                    </span>
                                    <button
                                      style={{
                                        marginLeft: "10px",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        color: "white",
                                        fontSize: "20px",
                                      }}
                                    >
                                      -
                                    </button>
                                    <span style={{ color: "white" }}>
                                      {shiftCodeData &&
                                      Array.isArray(
                                        shiftCodeData.shiftCodeItems
                                      ) &&
                                      shiftCodeData.shiftCodeItems.length > 1
                                        ? shiftCodeData.shiftCodeItems[1].timeOut
                                        : "Default TimeIn"}
                                    </span>
                                  </div>
                                )}
                                </>
                              )}
                            {remarks === "weekend" &&
                              ["Saturday", "Sunday"].includes(option.value) && (
                                <>
                                 {!check && (
                                  <div
                                    style={{
                                      backgroundColor: "#FF7201",
                                      height: "5vh",
                                      padding: "0px 5px",
                                      display: "inline-block",
                                      justifyContent: "center",
                                      alignContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{ color: "white" }}>
                                      {shiftCodeData &&
                                      Array.isArray(
                                        shiftCodeData.shiftCodeItems
                                      ) &&
                                      shiftCodeData.shiftCodeItems.length > 1
                                        ? shiftCodeData.shiftCodeItems[1].timeIn
                                        : "Default TimeIn"}
                                    </span>
                                    <button
                                      style={{
                                        marginLeft: "10px",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        color: "white",
                                        fontSize: "20px",
                                      }}
                                    >
                                      -
                                    </button>
                                    <span style={{ color: "white" }}>
                                      {shiftCodeData &&
                                      Array.isArray(
                                        shiftCodeData.shiftCodeItems
                                      ) &&
                                      shiftCodeData.shiftCodeItems.length > 1
                                        ? shiftCodeData.shiftCodeItems[1].timeOut
                                        : "Default TimeIn"}
                                    </span>
                                  </div>
                                )}
                                </>
                              )}
                          </Col>
                        </Row>
                      </Checkbox>
                    </div>
                  </div>
                ))}
                </>
              ): <CustomShiftList/>}
              
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={20} justify={"center"} style={{ marginTop: 20 }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#F47F20",
                  borderRadius: "83px",
                  width: "123.87px",
                }}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{
                  backgroundColor: "#2C2C2C",
                  borderRadius: "83px",
                  width: "123.87px",
                  color: "#ffff",
                }}
                onClick={() => handleCreateEmployeeShiftCancel()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(EmployeeShiftForm);
