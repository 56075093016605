import { ConfigProvider, Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import React from "react";

import Logo from "../../app/assets/images/third-logo.png";


const PrivacyPolicyContent = () => {
  return (
    <div className="pb-5">
      <h1>Data Privacy Clause for COMPASS</h1>
      <h2>Data Privacy Policy</h2>
      <h3>Introduction</h3>
      <p>Activations Advertising Inc. (“Company,” “we,” “our,” or “us”) is committed to protecting the privacy and security of your personal data. This Data Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use the COMPASS app (“App”), developed for tracking employee attendance and location in a hybrid working environment. By using the App, you consent to the collection and use of your data in accordance with this policy.</p>
      <ol>
        <li><strong>Data Collection</strong></li>
        <p>We collect the following types of personal data through the COMPASS app:
          <ul>
            <li><strong>Personal Information</strong>: Name, age, contact details, and location.</li>
            <li><strong>Biometric Data</strong>: Facial recognition data for attendance recording purposes.</li>
            <li><strong>Employment Information</strong>: Job title, department, work schedule, and related employment details.</li>
            <li><strong>Usage Data</strong>: Information about your interaction with the App, including timestamps and geolocation data during work hours.</li>
          </ul>
        </p>

        <li><strong>Purpose of Data Collection</strong></li>
        <p>The personal data collected through the COMPASS app will be used for the following purposes:
          <ul>
            <li>To accurately track and record employee attendance.</li>
            <li>To monitor employee location during work hours in compliance with our hybrid working policy.</li>
            <li>To ensure the security and efficiency of our operations.</li>
            <li>To comply with legal and regulatory requirements.</li>
            <li>To send notifications regarding job openings and other relevant updates.</li>
          </ul>
        </p>

        <li><strong>Data Storage and Security</strong></li>
        <p>We store your personal data in the AWS (Amazon Web Services) cloud, which implements robust security measures to protect your data against unauthorized access, alteration, disclosure, or destruction. We also implement appropriate technical and organizational measures to ensure the security and confidentiality of your personal data. Access to your data is restricted to authorized personnel only.</p>

        <li><strong>Data Sharing and Disclosure</strong></li>
        <p>We do not share your personal data with third parties except as necessary for the following purposes:
          <ul>
            <li>To comply with legal obligations or respond to lawful requests from public authorities.</li>
            <li>To protect and defend our rights and property.</li>
            <li>To ensure the safety of our employees and the public.</li>
          </ul>
        </p>

        <li><strong>Data Retention</strong></li>
        <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this policy, or as required by law. Once the data is no longer needed, it will be securely deleted or anonymized.</p>

        <li><strong>Your Rights</strong></li>
        <p>You have the following rights regarding your personal data:
          <ul>
            <li>The right to access your personal data and obtain a copy.</li>
            <li>The right to correct or update your personal data.</li>
            <li>he right to request the deletion of your personal data.</li>
            <li>The right to restrict or object to the processing of your personal data.</li>
            <li>The right to withdraw consent to the processing of your personal data at any time.</li>
          </ul>
        </p>

        <li><strong>Notifications and Updates</strong></li>
        <p>The COMPASS app will send notifications to users regarding job openings and other relevant updates. By using the App, you consent to receive such notifications. You can manage your notification preferences within the App settings.</p>

        <li><strong>Contact Information</strong></li>
        <p>If you have any questions or concerns about this Data Privacy Policy or the handling of your personal data, please contact our Data Privacy Officer:</p>
        <p>
          <strong>Data Privacy Officer</strong><br />
          Ma. Paulina Bianca B. Quintana<br />
          +63917 624 9693<br />
          Activations Advertising Inc.<br />
        </p>
        <li><strong>Policy Updates</strong></li>
        <p>We may update this Data Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website or through the App.</p>
        <strong>Effective Date: June 13, 2024</strong><br />
        By using the COMPASS app, you acknowledge that you have read, understood, and agreed to the terms of this Data Privacy Policy.
      </ol>
      <hr />
      <p>This clause ensures that employee data is collected, used, and protected in compliance with data privacy regulations in the Philippines. It also provides transparency and assures employees of their rights regarding their personal data.</p>
    </div>
  )
}

const PrivacyPolicy = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#00b96b',
          borderRadius: 2,

          // Alias Token
          colorBgContainer: '#f6ffed',
        },
      }}
    >
      <Layout>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: "#2C2C2C",
          }}
        >
          <a href="/" rel="noopener noreferrer">
            <img
              src={Logo}
              alt="Your Logo"
              style={{ width: "200px" }}
              className="flex justify-start lg:mr-40"
            />
          </a>
        </Header>
        <Content style={{
          padding: "0 50px"
        }}>
          <PrivacyPolicyContent />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default PrivacyPolicy;