import React from "react";
import { Row, Col, Layout } from "antd";
import EventRequestBody from "../../components/event-request/EventRequestBody";

const EventRequest = () => {
  return(
    <>
      <Layout style={{ minHeight: "80vh", padding: "20px" }}>
        <Row justify={"center"}>
          <Col>
            <EventRequestBody />
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default EventRequest;