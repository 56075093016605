import React from "react";
import { Form, Button, Input, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { usePositions } from "../../../app/hooks/usePositions";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

const AddPositionForm = ({ onSubmit }) => {
  AddPositionForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  const {create} = usePositions();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      await create(values).then(()=>{
        form.resetFields();
        onSubmit();
        toast.success("Successfully Added New Position", {
          position: toast.POSITION.TOP_RIGHT,
        })
      }).catch((error)=>{
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.title
        ) {
          // Extract error titles and display them in toasts
          toast.error(error.response.data.title, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Display a generic error message
          toast.error("An error occurred. Try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    } catch (error) {
      console.error("Form submission error:", error);
    }
  }
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Space
          style={{
            padding: "0 8px 4px",
          }}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Please enter Position" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button type="text" icon={<PlusOutlined />} htmlType="submit">
                Add item
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </>
  );
};

export default AddPositionForm;
